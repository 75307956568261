import "./App.css";
import { createContext, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Component/Header";
import Home from "./Component/Home";
import Footer from "./Component/Footer";
import CoursesPage from "./Component/CoursesPage";
import Login from "./Component/Login";
import Player from "./Component/Player";
import VideoPage from "./Component/VideosPage";
import TeachersPage from "./Component/TeachersPage";
import TeacherDetails from "./Component/TeacherDetails";
import BlogPage from "./Component/BlogPage";
import FivePage from "./Component/FivePage";
import PodcastDetailsPage from "./Component/PodcastDetailsPage";
import Modal from "./Component/Modal";
import AllPodcasts from "./Component/AllPodcasts";
import CourseDetailsPage from "./Component/CourseDetailsPage";
import ReservePage from "./Component/ReservePage";
export const AppContext = createContext();
function App() {
  const [userInfo, setUserInfo] = useState(null);
  const [player, setPlayer] = useState(null);
  return (
    <>
      <AppContext.Provider
        value={{
          userData: [userInfo, setUserInfo],
          playerInfo: [player, setPlayer],
        }}
      >
        <BrowserRouter>
          <Header />
          <Routes>
            <Route index path="/" element={<Home />}></Route>
            <Route path="/courses" element={<CoursesPage />}></Route>
            <Route
              path="/videos"
              element={<VideoPage pageName={"videos"} />}
            ></Route>
            <Route
              path="/cast"
              element={<VideoPage pageName={"casts"} />}
            ></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/teacher" element={<TeachersPage />}></Route>
            <Route path="/teacher/:id" element={<TeacherDetails />}></Route>
            <Route path="/blog" element={<BlogPage />}></Route>
            <Route path="/podcasts" element={<AllPodcasts />}></Route>
            <Route path="/podcast/:id" element={<PodcastDetailsPage />}></Route>
            <Route path="/course/:id" element={<CourseDetailsPage />}></Route>
            <Route path="/505" element={<FivePage />}></Route>
            <Route path="/reserve/:id/:name" element={<ReservePage />}></Route>
          </Routes>
          <Modal />
          <Player />
          <Footer />
        </BrowserRouter>
      </AppContext.Provider>
    </>
  );
}
export default App;
