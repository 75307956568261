import React, { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import NavsList from "./NavsList";
import "../Style/reservePage.css";
import { scrollToTop, showModal } from "./Methods";

export default function ReservePage(){
    const [data,setData] = useState({
        week : {
            hasPrevWeek : false,
            hasNextWeek : false,
            title : "",
            weekNumber : 0
        },
        days : [],
        page : 0,
        firstLoad : true,
    });
    const [reservedTimes , setReservedTimes] = useState({});
    const params = useParams();
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(false);
    const dayTimes = ["07:30","08:00","08:30","09:00","09:30","10:00","10:30","11:00","11:30","12:00","12:30",
        "13:00","13:30","14:00","14:30","15:00","15:30","16:00","16:30","17:00","17:30","18:00","18:30","19:00","19:30"
        ,"20:00","20:30","21:00","21:30","22:00","22:30","23:00","23:30"
    ];
    // tempData
    const tempCounter = useRef(0);
    const tempData = [
        {
            week : {
                hasPrevWeek : false,
                hasNextWeek : true,
                title : "۲۶ مهر - ۲ آبان",
                weekNumber : 550
            },
            days : [
                {
                    day : 12,
                    title :"پنج شنبه 26",
                    isHoliday : false,
                    isToday : false,
                    times:[
                        {
                            id : "12-1",
                            type : "inProgress",
                            time : "07:30 - 08:00",
                            title : 'پنج شنبه | 07:30'
                        },
                        {
                            id : "12-2",
                            type : "reserved",
                            time : "08:00 - 08:30",
                            title : 'پنج شنبه | 08:00'
                        },
                        {
                            id : "12-3",
                            type : "inProgress",
                            time : "08:30 - 09:00",
                            title : 'پنج شنبه | 08:30'
                        },
                        {
                            id : "12-4",
                            type : "unset",
                            time : "09:00 - 09:30",
                            title : 'پنج شنبه | 09:00'
                        },
                        {
                            id : "12-5",
                            type : "reserved",
                            time : "09:30 - 10:00",
                            title : 'پنج شنبه | 09:30'
                        },
                        {
                            id : "12-6",
                            type : "unset",
                            time : "10:00 - 10:30",
                            title : 'پنج شنبه | 10:00'
                        },
                        {
                            id : "12-7",
                            type : "unset",
                            time : "10:30 - 11:00",
                            title : 'پنج شنبه | 10:30'
                        },
                        {
                            id : "12-7",
                            type : "reserved",
                            time : "11:00 - 11:30",
                            title : 'پنج شنبه | 11:00'
                        },
                        {
                            id : "12-8",
                            type : "unset",
                            time : "11:30 - 12:00",
                            title : 'پنج شنبه | 11:30'
                        },
                        {
                            id : "12-9",
                            type : "unset",
                            time : "12:00 - 12:30",
                            title : 'پنج شنبه | 12:00'
                        },
                        {
                            id : "12-10",
                            type : "unset",
                            time : "12:30 - 13:00",
                            title : 'پنج شنبه | 12:30'
                        },
                        {
                            id : "12-11",
                            type : "unset",
                            time : "13:00 - 13:30",
                            title : 'پنج شنبه | 13:00'
                        },
                        {
                            id : "12-12",
                            type : "unset",
                            time : "13:30 - 14:00",
                            title : 'پنج شنبه | 13:30'
                        },
                        {
                            id : "12-13",
                            type : "unset",
                            time : "14:00 - 14:30",
                            title : 'پنج شنبه | 14:00'
                        },
                        {
                            id : "12-14",
                            type : "unset",
                            time : "14:30 - 15:00",
                            title : 'پنج شنبه | 14:30'
                        },
                        {
                            id : "12-15",
                            type : "reserved",
                            time : "15:00 - 15:30",
                            title : 'پنج شنبه | 15:00'
                        },
                        {
                            id : "12-16",
                            type : "unset",
                            time : "15:30 - 16:00",
                            title : 'پنج شنبه | 15:30'
                        },
                        {
                            id : "12-17",
                            type : "unset",
                            time : "16:00 - 16:30",
                            title : 'پنج شنبه | 16:00'
                        },
                        {
                            id : "12-18",
                            type : "unset",
                            time : "16:30 - 17:00",
                            title : 'پنج شنبه | 16:30'
                        },
                        {
                            id : "12-19",
                            type : "unset",
                            time : "17:00 - 17:30",
                            title : 'پنج شنبه | 17:00'
                        },
                        {
                            id : "12-20",
                            type : "unset",
                            time : "17:30 - 18:00",
                            title : 'پنج شنبه | 17:30'
                        },
                        {
                            id : "12-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'پنج شنبه | 18:00'
                        },
                        {
                            id : "12-21",
                            type : "unset",
                            time : "18:30 - 19:00",
                            title : 'پنج شنبه | 18:30'
                        },
                        {
                            id : "12-22",
                            type : "unset",
                            time : "19:00 - 19:30",
                            title : 'پنج شنبه | 19:00'
                        },
                        {
                            id : "12-23",
                            type : "available",
                            time : "19:30 - 20:00",
                            title : 'پنج شنبه | 19:30'
                        },
                        {
                            id : "12-24",
                            type : "available",
                            time : "20:00 - 20:30",
                            title : 'پنج شنبه | 20:00'
                        },
                        {
                            id : "12-25",
                            type : "available",
                            time : "20:30 - 21:00",
                            title : 'پنج شنبه | 20:30'
                        },
                        {
                            id : "12-26",
                            type : "reserved",
                            time : "21:00 - 21:30",
                            title : 'پنج شنبه | 21:00'
                        },
                        {
                            id : "12-27",
                            type : "available",
                            time : "21:30 - 22:00",
                            title : 'پنج شنبه | 21:30'
                        },
                        {
                            id : "12-28",
                            type : "available",
                            time : "22:00 - 22:30",
                            title : 'پنج شنبه | 22:00'
                        },
                        {
                            id : "12-29",
                            type : "available",
                            time : "22:30 - 23:00",
                            title : 'پنج شنبه | 22:30'
                        },
                        {
                            id : "12-30",
                            type : "inProgress",
                            time : "23:00 - 23:30",
                            title : 'پنج شنبه | 23:00'
                        },
                        {
                            id : "12-26",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'پنج شنبه | 23:30'
                        },
                    ]
                },
                {
                    day : 13,
                    title :"جمعه 27",
                    isHoliday : true,
                    isToday : false,
                    times:[
                        {
                            id : "13-1",
                            type : "unset",
                            time : "07:30 - 08:00",
                            title : 'جمعه | 07:30'
                        },
                        {
                            id : "13-2",
                            type : "reserved",
                            time : "08:00 - 08:30",
                            title : 'جمعه | 08:00'
                        },
                        {
                            id : "13-3",
                            type : "unset",
                            time : "08:30 - 09:00",
                            title : 'جمعه | 08:30'
                        },
                        {
                            id : "13-4",
                            type : "unset",
                            time : "09:00 - 09:30",
                            title : 'جمعه | 09:00'
                        },
                        {
                            id : "13-5",
                            type : "inProgress",
                            time : "09:30 - 10:00",
                            title : 'جمعه | 09:30'
                        },
                        {
                            id : "13-6",
                            type : "reserved",
                            time : "10:00 - 10:30",
                            title : 'جمعه | 10:00'
                        },
                        {
                            id : "13-7",
                            type : "unset",
                            time : "10:30 - 11:00",
                            title : 'جمعه | 10:30'
                        },
                        {
                            id : "13-7",
                            type : "unset",
                            time : "11:00 - 11:30",
                            title : 'جمعه | 11:00'
                        },
                        {
                            id : "13-8",
                            type : "unset",
                            time : "11:30 - 12:00",
                            title : 'جمعه | 11:30'
                        },
                        {
                            id : "13-9",
                            type : "unset",
                            time : "12:00 - 12:30",
                            title : 'جمعه | 12:00'
                        },
                        {
                            id : "13-10",
                            type : "available",
                            time : "12:30 - 13:00",
                            title : 'جمعه | 12:30'
                        },
                        {
                            id : "13-11",
                            type : "available",
                            time : "13:00 - 13:30",
                            title : 'جمعه | 13:00'
                        },
                        {
                            id : "13-12",
                            type : "available",
                            time : "13:30 - 14:00",
                            title : 'جمعه | 13:30'
                        },
                        {
                            id : "13-13",
                            type : "inProgress",
                            time : "14:00 - 14:30",
                            title : 'جمعه | 14:00'
                        },
                        {
                            id : "13-14",
                            type : "available",
                            time : "14:30 - 15:00",
                            title : 'جمعه | 14:30'
                        },
                        {
                            id : "13-15",
                            type : "available",
                            time : "15:00 - 15:30",
                            title : 'جمعه | 15:00'
                        },
                        {
                            id : "13-16",
                            type : "available",
                            time : "15:30 - 16:00",
                            title : 'جمعه | 15:30'
                        },
                        {
                            id : "13-17",
                            type : "available",
                            time : "16:00 - 16:30",
                            title : 'جمعه | 16:00'
                        },
                        {
                            id : "13-18",
                            type : "available",
                            time : "16:30 - 17:00",
                            title : 'جمعه | 16:30'
                        },
                        {
                            id : "13-19",
                            type : "available",
                            time : "17:00 - 17:30",
                            title : 'جمعه | 17:00'
                        },
                        {
                            id : "13-20",
                            type : "reserved",
                            time : "17:30 - 18:00",
                            title : 'جمعه | 17:30'
                        },
                        {
                            id : "13-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'جمعه | 18:00'
                        },
                        {
                            id : "13-21",
                            type : "unset",
                            time : "18:30 - 19:00",
                            title : 'جمعه | 18:30'
                        },
                        {
                            id : "13-22",
                            type : "inProgress",
                            time : "19:00 - 19:30",
                            title : 'جمعه | 19:00'
                        },
                        {
                            id : "13-23",
                            type : "unset",
                            time : "19:30 - 20:00",
                            title : 'جمعه | 19:30'
                        },
                        {
                            id : "13-24",
                            type : "unset",
                            time : "20:00 - 20:30",
                            title : 'جمعه | 20:00'
                        },
                        {
                            id : "13-25",
                            type : "reserved",
                            time : "20:30 - 21:00",
                            title : 'جمعه | 20:30'
                        },
                        {
                            id : "13-26",
                            type : "reserved",
                            time : "21:00 - 21:30",
                            title : 'جمعه | 21:00'
                        },
                        {
                            id : "13-27",
                            type : "unset",
                            time : "21:30 - 22:00",
                            title : 'جمعه | 21:30'
                        },
                        {
                            id : "13-28",
                            type : "unset",
                            time : "22:00 - 22:30",
                            title : 'جمعه | 22:00'
                        },
                        {
                            id : "13-29",
                            type : "unset",
                            time : "22:30 - 23:00",
                            title : 'جمعه | 22:30'
                        },
                        {
                            id : "13-30",
                            type : "unset",
                            time : "23:00 - 23:30",
                            title : 'جمعه | 23:00'
                        },
                        {
                            id : "13-26",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'جمعه | 23:30'
                        },
                    ]
                },
                {
                    day : 14,
                    title :"شنبه 28",
                    isHoliday : false,
                    isToday : false,
                    times:[
                        {
                            id : "14-1",
                            type : "unset",
                            time : "07:30 - 08:00",
                            title : 'شنبه | 07:30'
                        },
                        {
                            id : "14-2",
                            type : "unset",
                            time : "08:00 - 08:30",
                            title : 'شنبه | 08:00'
                        },
                        {
                            id : "14-3",
                            type : "reserved",
                            time : "08:30 - 09:00",
                            title : 'شنبه | 08:30'
                        },
                        {
                            id : "14-4",
                            type : "unset",
                            time : "09:00 - 09:30",
                            title : 'شنبه | 09:00'
                        },
                        {
                            id : "14-5",
                            type : "reserved",
                            time : "09:30 - 10:00",
                            title : 'شنبه | 09:30'
                        },
                        {
                            id : "14-6",
                            type : "available",
                            time : "10:00 - 10:30",
                            title : 'شنبه | 10:00'
                        },
                        {
                            id : "14-7",
                            type : "inProgress",
                            time : "10:30 - 11:00",
                            title : 'شنبه | 10:30'
                        },
                        {
                            id : "14-72",
                            type : "available",
                            time : "11:00 - 11:30",
                            title : 'شنبه | 11:00'
                        },
                        {
                            id : "14-8",
                            type : "available",
                            time : "11:30 - 12:00",
                            title : 'شنبه | 11:30'
                        },
                        {
                            id : "14-9",
                            type : "available",
                            time : "12:00 - 12:30",
                            title : 'شنبه | 12:00'
                        },
                        {
                            id : "14-10",
                            type : "reserved",
                            time : "12:30 - 13:00",
                            title : 'شنبه | 12:30'
                        },
                        {
                            id : "14-11",
                            type : "available",
                            time : "13:00 - 13:30",
                            title : 'شنبه | 13:00'
                        },
                        {
                            id : "14-12",
                            type : "available",
                            time : "13:30 - 14:00",
                            title : 'شنبه | 13:30'
                        },
                        {
                            id : "14-13",
                            type : "available",
                            time : "14:00 - 14:30",
                            title : 'شنبه | 14:00'
                        },
                        {
                            id : "14-14",
                            type : "available",
                            time : "14:30 - 15:00",
                            title : 'شنبه | 14:30'
                        },
                        {
                            id : "14-15",
                            type : "available",
                            time : "15:00 - 15:30",
                            title : 'شنبه | 15:00'
                        },
                        {
                            id : "14-16",
                            type : "unset",
                            time : "15:30 - 16:00",
                            title : 'شنبه | 15:30'
                        },
                        {
                            id : "14-17",
                            type : "unset",
                            time : "16:00 - 16:30",
                            title : 'شنبه | 16:00'
                        },
                        {
                            id : "14-18",
                            type : "unset",
                            time : "16:30 - 17:00",
                            title : 'شنبه | 16:30'
                        },
                        {
                            id : "14-19",
                            type : "unset",
                            time : "17:00 - 17:30",
                            title : 'شنبه | 17:00'
                        },
                        {
                            id : "14-20",
                            type : "reserved",
                            time : "17:30 - 18:00",
                            title : 'شنبه | 17:30'
                        },
                        {
                            id : "14-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'شنبه | 18:00'
                        },
                        {
                            id : "14-21",
                            type : "inProgress",
                            time : "18:30 - 19:00",
                            title : 'شنبه | 18:30'
                        },
                        {
                            id : "14-22",
                            type : "unset",
                            time : "19:00 - 19:30",
                            title : 'شنبه | 19:00'
                        },
                        {
                            id : "14-23",
                            type : "unset",
                            time : "19:30 - 20:00",
                            title : 'شنبه | 19:30'
                        },
                        {
                            id : "14-24",
                            type : "unset",
                            time : "20:00 - 20:30",
                            title : 'شنبه | 20:00'
                        },
                        {
                            id : "14-25",
                            type : "unset",
                            time : "20:30 - 21:00",
                            title : 'شنبه | 20:30'
                        },
                        {
                            id : "14-26",
                            type : "unset",
                            time : "21:00 - 21:30",
                            title : 'شنبه | 21:00'
                        },
                        {
                            id : "14-27",
                            type : "unset",
                            time : "21:30 - 22:00",
                            title : 'شنبه | 21:30'
                        },
                        {
                            id : "14-28",
                            type : "available",
                            time : "22:00 - 22:30",
                            title : 'شنبه | 22:00'
                        },
                        {
                            id : "14-29",
                            type : "available",
                            time : "22:30 - 23:00",
                            title : 'شنبه | 22:30'
                        },
                        {
                            id : "14-30",
                            type : "reserved",
                            time : "23:00 - 23:30",
                            title : 'شنبه | 23:00'
                        },
                        {
                            id : "14-26",
                            type : "available",
                            time : "23:30 - 00:00",
                            title : 'شنبه | 23:30'
                        },
                    ]
                },
                {
                    day : 15,
                    title :"یکشنبه 29",
                    isHoliday : false,
                    isToday : false,
                    times:[
                        {
                            id : "15-1",
                            type : "available",
                            time : "07:30 - 08:00",
                            title : 'یکشنبه | 07:30'
                        },
                        {
                            id : "15-2",
                            type : "available",
                            time : "08:00 - 08:30",
                            title : 'یکشنبه | 08:00'
                        },
                        {
                            id : "15-3",
                            type : "available",
                            time : "08:30 - 09:00",
                            title : 'یکشنبه | 08:30'
                        },
                        {
                            id : "15-4",
                            type : "available",
                            time : "09:00 - 09:30",
                            title : 'یکشنبه | 09:00'
                        },
                        {
                            id : "15-5",
                            type : "unset",
                            time : "09:30 - 10:00",
                            title : 'یکشنبه | 09:30'
                        },
                        {
                            id : "15-6",
                            type : "reserved",
                            time : "10:00 - 10:30",
                            title : 'یکشنبه | 10:00'
                        },
                        {
                            id : "15-7",
                            type : "unset",
                            time : "10:30 - 11:00",
                            title : 'یکشنبه | 10:30'
                        },
                        {
                            id : "15-7",
                            type : "unset",
                            time : "11:00 - 11:30",
                            title : 'یکشنبه | 11:00'
                        },
                        {
                            id : "15-8",
                            type : "unset",
                            time : "11:30 - 12:00",
                            title : 'یکشنبه | 11:30'
                        },
                        {
                            id : "15-9",
                            type : "reserved",
                            time : "12:00 - 12:30",
                            title : 'یکشنبه | 12:00'
                        },
                        {
                            id : "15-10",
                            type : "reserved",
                            time : "12:30 - 13:00",
                            title : 'یکشنبه | 12:30'
                        },
                        {
                            id : "15-11",
                            type : "reserved",
                            time : "13:00 - 13:30",
                            title : 'یکشنبه | 13:00'
                        },
                        {
                            id : "15-12",
                            type : "unset",
                            time : "13:30 - 14:00",
                            title : 'یکشنبه | 13:30'
                        },
                        {
                            id : "15-13",
                            type : "inProgress",
                            time : "14:00 - 14:30",
                            title : 'یکشنبه | 14:00'
                        },
                        {
                            id : "15-14",
                            type : "unset",
                            time : "14:30 - 15:00",
                            title : 'یکشنبه | 14:30'
                        },
                        {
                            id : "15-15",
                            type : "unset",
                            time : "15:00 - 15:30",
                            title : 'یکشنبه | 15:00'
                        },
                        {
                            id : "15-16",
                            type : "available",
                            time : "15:30 - 16:00",
                            title : 'یکشنبه | 15:30'
                        },
                        {
                            id : "15-17",
                            type : "reserved",
                            time : "16:00 - 16:30",
                            title : 'یکشنبه | 16:00'
                        },
                        {
                            id : "15-18",
                            type : "reserved",
                            time : "16:30 - 17:00",
                            title : 'یکشنبه | 16:30'
                        },
                        {
                            id : "15-19",
                            type : "reserved",
                            time : "17:00 - 17:30",
                            title : 'یکشنبه | 17:00'
                        },
                        {
                            id : "15-20",
                            type : "available",
                            time : "17:30 - 18:00",
                            title : 'یکشنبه | 17:30'
                        },
                        {
                            id : "15-20",
                            type : "available",
                            time : "18:00 - 18:30",
                            title : 'یکشنبه | 18:00'
                        },
                        {
                            id : "15-21",
                            type : "available",
                            time : "18:30 - 19:00",
                            title : 'یکشنبه | 18:30'
                        },
                        {
                            id : "15-22",
                            type : "available",
                            time : "19:00 - 19:30",
                            title : 'یکشنبه | 19:00'
                        },
                        {
                            id : "15-23",
                            type : "available",
                            time : "19:30 - 20:00",
                            title : 'یکشنبه | 19:30'
                        },
                        {
                            id : "15-24",
                            type : "inProgress",
                            time : "20:00 - 20:30",
                            title : 'یکشنبه | 20:00'
                        },
                        {
                            id : "15-25",
                            type : "unset",
                            time : "20:30 - 21:00",
                            title : 'یکشنبه | 20:30'
                        },
                        {
                            id : "15-26",
                            type : "unset",
                            time : "21:00 - 21:30",
                            title : 'یکشنبه | 21:00'
                        },
                        {
                            id : "15-27",
                            type : "unset",
                            time : "21:30 - 22:00",
                            title : 'یکشنبه | 21:30'
                        },
                        {
                            id : "15-28",
                            type : "unset",
                            time : "22:00 - 22:30",
                            title : 'یکشنبه | 22:00'
                        },
                        {
                            id : "15-29",
                            type : "unset",
                            time : "22:30 - 23:00",
                            title : 'یکشنبه | 22:30'
                        },
                        {
                            id : "15-30",
                            type : "unset",
                            time : "23:00 - 23:30",
                            title : 'یکشنبه | 23:00'
                        },
                        {
                            id : "15-26",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'یکشنبه | 23:30'
                        },
                    ]
                },
                {
                    day : 16,
                    title :"دوشنبه 30",
                    isHoliday : false,
                    isToday : false,
                    times:[
                        {
                            id : "16-1",
                            type : "unset",
                            time : "07:30 - 08:00",
                            title : 'دوشنبه | 07:30'
                        },
                        {
                            id : "16-2",
                            type : "unset",
                            time : "08:00 - 08:30",
                            title : 'دوشنبه | 08:00'
                        },
                        {
                            id : "16-3",
                            type : "inProgress",
                            time : "08:30 - 09:00",
                            title : 'دوشنبه | 08:30'
                        },
                        {
                            id : "16-4",
                            type : "reserved",
                            time : "09:00 - 09:30",
                            title : 'دوشنبه | 09:00'
                        },
                        {
                            id : "16-5",
                            type : "reserved",
                            time : "09:30 - 10:00",
                            title : 'دوشنبه | 09:30'
                        },
                        {
                            id : "16-6",
                            type : "reserved",
                            time : "10:00 - 10:30",
                            title : 'دوشنبه | 10:00'
                        },
                        {
                            id : "16-7",
                            type : "unset",
                            time : "10:30 - 11:00",
                            title : 'دوشنبه | 10:30'
                        },
                        {
                            id : "16-7",
                            type : "unset",
                            time : "11:00 - 11:30",
                            title : 'دوشنبه | 11:00'
                        },
                        {
                            id : "16-8",
                            type : "available",
                            time : "11:30 - 12:00",
                            title : 'دوشنبه | 11:30'
                        },
                        {
                            id : "16-9",
                            type : "available",
                            time : "12:00 - 12:30",
                            title : 'دوشنبه | 12:00'
                        },
                        {
                            id : "16-10",
                            type : "available",
                            time : "12:30 - 13:00",
                            title : 'دوشنبه | 12:30'
                        },
                        {
                            id : "16-11",
                            type : "available",
                            time : "13:00 - 13:30",
                            title : 'دوشنبه | 13:00'
                        },
                        {
                            id : "16-12",
                            type : "reserved",
                            time : "13:30 - 14:00",
                            title : 'دوشنبه | 13:30'
                        },
                        {
                            id : "16-13",
                            type : "available",
                            time : "14:00 - 14:30",
                            title : 'دوشنبه | 14:00'
                        },
                        {
                            id : "16-14",
                            type : "available",
                            time : "14:30 - 15:00",
                            title : 'دوشنبه | 14:30'
                        },
                        {
                            id : "16-15",
                            type : "unset",
                            time : "15:00 - 15:30",
                            title : 'دوشنبه | 15:00'
                        },
                        {
                            id : "16-16",
                            type : "unset",
                            time : "15:30 - 16:00",
                            title : 'دوشنبه | 15:30'
                        },
                        {
                            id : "16-17",
                            type : "unset",
                            time : "16:00 - 16:30",
                            title : 'دوشنبه | 16:00'
                        },
                        {
                            id : "16-18",
                            type : "reserved",
                            time : "16:30 - 17:00",
                            title : 'دوشنبه | 16:30'
                        },
                        {
                            id : "16-19",
                            type : "reserved",
                            time : "17:00 - 17:30",
                            title : 'دوشنبه | 17:00'
                        },
                        {
                            id : "16-20",
                            type : "unset",
                            time : "17:30 - 18:00",
                            title : 'دوشنبه | 17:30'
                        },
                        {
                            id : "16-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'دوشنبه | 18:00'
                        },
                        {
                            id : "16-21",
                            type : "inProgress",
                            time : "18:30 - 19:00",
                            title : 'دوشنبه | 18:30'
                        },
                        {
                            id : "16-22",
                            type : "unset",
                            time : "19:00 - 19:30",
                            title : 'دوشنبه | 19:00'
                        },
                        {
                            id : "16-23",
                            type : "unset",
                            time : "19:30 - 20:00",
                            title : 'دوشنبه | 19:30'
                        },
                        {
                            id : "16-24",
                            type : "unset",
                            time : "20:00 - 20:30",
                            title : 'دوشنبه | 20:00'
                        },
                        {
                            id : "16-25",
                            type : "unset",
                            time : "20:30 - 21:00",
                            title : 'دوشنبه | 20:30'
                        },
                        {
                            id : "16-26",
                            type : "unset",
                            time : "21:00 - 21:30",
                            title : 'دوشنبه | 21:00'
                        },
                        {
                            id : "16-27",
                            type : "reserved",
                            time : "21:30 - 22:00",
                            title : 'دوشنبه | 21:30'
                        },
                        {
                            id : "16-28",
                            type : "unset",
                            time : "22:00 - 22:30",
                            title : 'دوشنبه | 22:00'
                        },
                        {
                            id : "16-29",
                            type : "unset",
                            time : "22:30 - 23:00",
                            title : 'دوشنبه | 22:30'
                        },
                        {
                            id : "16-30",
                            type : "unset",
                            time : "23:00 - 23:30",
                            title : 'دوشنبه | 23:00'
                        },
                        {
                            id : "16-26",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'دوشنبه | 23:30'
                        },
                    ]
                },
                {
                    day : 17,
                    title :"سه‌شنبه 01",
                    isHoliday : false,
                    isToday : false,
                    times:[
                        {
                            id : "17-1",
                            type : "unset",
                            time : "07:30 - 08:00",
                            title : 'سه‌شنبه | 07:30'
                        },
                        {
                            id : "17-2",
                            type : "unset",
                            time : "08:00 - 08:30",
                            title : 'سه‌شنبه | 08:00'
                        },
                        {
                            id : "17-3",
                            type : "inProgress",
                            time : "08:30 - 09:00",
                            title : 'سه‌شنبه | 08:30'
                        },
                        {
                            id : "17-4",
                            type : "unset",
                            time : "09:00 - 09:30",
                            title : 'سه‌شنبه | 09:00'
                        },
                        {
                            id : "17-5",
                            type : "reserved",
                            time : "09:30 - 10:00",
                            title : 'سه‌شنبه | 09:30'
                        },
                        {
                            id : "17-6",
                            type : "unset",
                            time : "10:00 - 10:30",
                            title : 'سه‌شنبه | 10:00'
                        },
                        {
                            id : "17-7",
                            type : "unset",
                            time : "10:30 - 11:00",
                            title : 'سه‌شنبه | 10:30'
                        },
                        {
                            id : "17-7",
                            type : "unset",
                            time : "11:00 - 11:30",
                            title : 'سه‌شنبه | 11:00'
                        },
                        {
                            id : "17-8",
                            type : "available",
                            time : "11:30 - 12:00",
                            title : 'سه‌شنبه | 11:30'
                        },
                        {
                            id : "17-9",
                            type : "available",
                            time : "12:00 - 12:30",
                            title : 'سه‌شنبه | 12:00'
                        },
                        {
                            id : "17-10",
                            type : "available",
                            time : "12:30 - 13:00",
                            title : 'سه‌شنبه | 12:30'
                        },
                        {
                            id : "17-11",
                            type : "available",
                            time : "13:00 - 13:30",
                            title : 'سه‌شنبه | 13:00'
                        },
                        {
                            id : "17-12",
                            type : "available",
                            time : "13:30 - 14:00",
                            title : 'سه‌شنبه | 13:30'
                        },
                        {
                            id : "17-13",
                            type : "available",
                            time : "14:00 - 14:30",
                            title : 'سه‌شنبه | 14:00'
                        },
                        {
                            id : "17-14",
                            type : "available",
                            time : "14:30 - 15:00",
                            title : 'سه‌شنبه | 14:30'
                        },
                        {
                            id : "17-15",
                            type : "available",
                            time : "15:00 - 15:30",
                            title : 'سه‌شنبه | 15:00'
                        },
                        {
                            id : "17-16",
                            type : "available",
                            time : "15:30 - 16:00",
                            title : 'سه‌شنبه | 15:30'
                        },
                        {
                            id : "17-17",
                            type : "reserved",
                            time : "16:00 - 16:30",
                            title : 'سه‌شنبه | 16:00'
                        },
                        {
                            id : "17-18",
                            type : "available",
                            time : "16:30 - 17:00",
                            title : 'سه‌شنبه | 16:30'
                        },
                        {
                            id : "17-19",
                            type : "unset",
                            time : "17:00 - 17:30",
                            title : 'سه‌شنبه | 17:00'
                        },
                        {
                            id : "17-20",
                            type : "unset",
                            time : "17:30 - 18:00",
                            title : 'سه‌شنبه | 17:30'
                        },
                        {
                            id : "17-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'سه‌شنبه | 18:00'
                        },
                        {
                            id : "17-21",
                            type : "unset",
                            time : "18:30 - 19:00",
                            title : 'سه‌شنبه | 18:30'
                        },
                        {
                            id : "17-22",
                            type : "unset",
                            time : "19:00 - 19:30",
                            title : 'سه‌شنبه | 19:00'
                        },
                        {
                            id : "17-23",
                            type : "reserved",
                            time : "19:30 - 20:00",
                            title : 'سه‌شنبه | 19:30'
                        },
                        {
                            id : "17-24",
                            type : "unset",
                            time : "20:00 - 20:30",
                            title : 'سه‌شنبه | 20:00'
                        },
                        {
                            id : "17-25",
                            type : "unset",
                            time : "20:30 - 21:00",
                            title : 'سه‌شنبه | 20:30'
                        },
                        {
                            id : "17-26",
                            type : "unset",
                            time : "21:00 - 21:30",
                            title : 'سه‌شنبه | 21:00'
                        },
                        {
                            id : "17-27",
                            type : "inProgress",
                            time : "21:30 - 22:00",
                            title : 'سه‌شنبه | 21:30'
                        },
                        {
                            id : "17-28",
                            type : "unset",
                            time : "22:00 - 22:30",
                            title : 'سه‌شنبه | 22:00'
                        },
                        {
                            id : "17-29",
                            type : "reserved",
                            time : "22:30 - 23:00",
                            title : 'سه‌شنبه | 22:30'
                        },
                        {
                            id : "17-30",
                            type : "unset",
                            time : "23:00 - 23:30",
                            title : 'سه‌شنبه | 23:00'
                        },
                        {
                            id : "17-26",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'سه‌شنبه | 23:30'
                        },
                    ]
                },
                {
                    day : 18,
                    title :"چهارشنبه 02",
                    isHoliday : false,
                    isToday : true,
                    times:[
                        {
                            id : "18-1",
                            type : "unset",
                            time : "07:30 - 08:00",
                            title : 'چهارشنبه | 07:30'
                        },
                        {
                            id : "18-2",
                            type : "unset",
                            time : "08:00 - 08:30",
                            title : 'چهارشنبه | 08:00'
                        },
                        {
                            id : "18-3",
                            type : "unset",
                            time : "08:30 - 09:00",
                            title : 'چهارشنبه | 08:30'
                        },
                        {
                            id : "18-4",
                            type : "reserved",
                            time : "09:00 - 09:30",
                            title : 'چهارشنبه | 09:00'
                        },
                        {
                            id : "18-5",
                            type : "reserved",
                            time : "09:30 - 10:00",
                            title : 'چهارشنبه | 09:30'
                        },
                        {
                            id : "18-6",
                            type : "reserved",
                            time : "10:00 - 10:30",
                            title : 'چهارشنبه | 10:00'
                        },
                        {
                            id : "18-7",
                            type : "reserved",
                            time : "10:30 - 11:00",
                            title : 'چهارشنبه | 10:30'
                        },
                        {
                            id : "18-7",
                            type : "reserved",
                            time : "11:00 - 11:30",
                            title : 'چهارشنبه | 11:00'
                        },
                        {
                            id : "18-8",
                            type : "reserved",
                            time : "11:30 - 12:00",
                            title : 'چهارشنبه | 11:30'
                        },
                        {
                            id : "18-9",
                            type : "available",
                            time : "12:00 - 12:30",
                            title : 'چهارشنبه | 12:00'
                        },
                        {
                            id : "18-10",
                            type : "available",
                            time : "12:30 - 13:00",
                            title : 'چهارشنبه | 12:30'
                        },
                        {
                            id : "18-11",
                            type : "available",
                            time : "13:00 - 13:30",
                            title : 'چهارشنبه | 13:00'
                        },
                        {
                            id : "18-12",
                            type : "available",
                            time : "13:30 - 14:00",
                            title : 'چهارشنبه | 13:30'
                        },
                        {
                            id : "18-13",
                            type : "available",
                            time : "14:00 - 14:30",
                            title : 'چهارشنبه | 14:00'
                        },
                        {
                            id : "18-14",
                            type : "unset",
                            time : "14:30 - 15:00",
                            title : 'چهارشنبه | 14:30'
                        },
                        {
                            id : "18-15",
                            type : "unset",
                            time : "15:00 - 15:30",
                            title : 'چهارشنبه | 15:00'
                        },
                        {
                            id : "18-16",
                            type : "unset",
                            time : "15:30 - 16:00",
                            title : 'چهارشنبه | 15:30'
                        },
                        {
                            id : "18-17",
                            type : "unset",
                            time : "16:00 - 16:30",
                            title : 'چهارشنبه | 16:00'
                        },
                        {
                            id : "18-18",
                            type : "unset",
                            time : "16:30 - 17:00",
                            title : 'چهارشنبه | 16:30'
                        },
                        {
                            id : "18-19",
                            type : "reserved",
                            time : "17:00 - 17:30",
                            title : 'چهارشنبه | 17:00'
                        },
                        {
                            id : "18-20",
                            type : "unset",
                            time : "17:30 - 18:00",
                            title : 'چهارشنبه | 17:30'
                        },
                        {
                            id : "18-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'چهارشنبه | 18:00'
                        },
                        {
                            id : "18-21",
                            type : "unset",
                            time : "18:30 - 19:00",
                            title : 'چهارشنبه | 18:30'
                        },
                        {
                            id : "18-22",
                            type : "unset",
                            time : "19:00 - 19:30",
                            title : 'چهارشنبه | 19:00'
                        },
                        {
                            id : "18-23",
                            type : "unset",
                            time : "19:30 - 20:00",
                            title : 'چهارشنبه | 19:30'
                        },
                        {
                            id : "18-24",
                            type : "unset",
                            time : "20:00 - 20:30",
                            title : 'چهارشنبه | 20:00'
                        },
                        {
                            id : "18-25",
                            type : "unset",
                            time : "20:30 - 21:00",
                            title : 'چهارشنبه | 20:30'
                        },
                        {
                            id : "18-26",
                            type : "unset",
                            time : "21:00 - 21:30",
                            title : 'چهارشنبه | 21:00'
                        },
                        {
                            id : "18-27",
                            type : "unset",
                            time : "21:30 - 22:00",
                            title : 'چهارشنبه | 21:30'
                        },
                        {
                            id : "18-28",
                            type : "unset",
                            time : "22:00 - 22:30",
                            title : 'چهارشنبه | 22:00'
                        },
                        {
                            id : "18-29",
                            type : "unset",
                            time : "22:30 - 23:00",
                            title : 'چهارشنبه | 22:30'
                        },
                        {
                            id : "18-30",
                            type : "unset",
                            time : "23:00 - 23:30",
                            title : 'چهارشنبه | 23:00'
                        },
                        {
                            id : "18-26",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'چهارشنبه | 23:30'
                        },
                    ]
                }
            ],
            page : 0
        },
        {
            week : {
                hasPrevWeek : true,
                hasNextWeek : false,
                title : "۳ آبان - ۹ آبان",
                weekNumber : 553
            },
            days : [
                {
                    day : 19,
                    title :"پنج شنبه 03",
                    isHoliday : false,
                    isToday : false,
                    times:[
                        {
                            id : "19-1",
                            type : "unset",
                            time : "07:30 - 08:00",
                            title : 'پنج شنبه | 07:30'
                        },
                        {
                            id : "19-2",
                            type : "reserved",
                            time : "08:00 - 08:30",
                            title : 'پنج شنبه | 08:00'
                        },
                        {
                            id : "19-3",
                            type : "unset",
                            time : "08:30 - 09:00",
                            title : 'پنج شنبه | 08:30'
                        },
                        {
                            id : "19-4",
                            type : "unset",
                            time : "09:00 - 09:30",
                            title : 'پنج شنبه | 09:00'
                        },
                        {
                            id : "19-5",
                            type : "reserved",
                            time : "09:30 - 10:00",
                            title : 'پنج شنبه | 09:30'
                        },
                        {
                            id : "19-6",
                            type : "unset",
                            time : "10:00 - 10:30",
                            title : 'پنج شنبه | 10:00'
                        },
                        {
                            id : "19-7",
                            type : "unset",
                            time : "10:30 - 11:00",
                            title : 'پنج شنبه | 10:30'
                        },
                        {
                            id : "19-7",
                            type : "unset",
                            time : "11:00 - 11:30",
                            title : 'پنج شنبه | 11:00'
                        },
                        {
                            id : "19-8",
                            type : "unset",
                            time : "11:30 - 12:00",
                            title : 'پنج شنبه | 11:30'
                        },
                        {
                            id : "19-9",
                            type : "unset",
                            time : "12:00 - 12:30",
                            title : 'پنج شنبه | 12:00'
                        },
                        {
                            id : "19-10",
                            type : "unset",
                            time : "12:30 - 13:00",
                            title : 'پنج شنبه | 12:30'
                        },
                        {
                            id : "19-11",
                            type : "unset",
                            time : "13:00 - 13:30",
                            title : 'پنج شنبه | 13:00'
                        },
                        {
                            id : "19-12",
                            type : "unset",
                            time : "13:30 - 14:00",
                            title : 'پنج شنبه | 13:30'
                        },
                        {
                            id : "19-13",
                            type : "unset",
                            time : "14:00 - 14:30",
                            title : 'پنج شنبه | 14:00'
                        },
                        {
                            id : "19-14",
                            type : "unset",
                            time : "14:30 - 15:00",
                            title : 'پنج شنبه | 14:30'
                        },
                        {
                            id : "19-15",
                            type : "reserved",
                            time : "15:00 - 15:30",
                            title : 'پنج شنبه | 15:00'
                        },
                        {
                            id : "19-16",
                            type : "unset",
                            time : "15:30 - 16:00",
                            title : 'پنج شنبه | 15:30'
                        },
                        {
                            id : "19-17",
                            type : "unset",
                            time : "16:00 - 16:30",
                            title : 'پنج شنبه | 16:00'
                        },
                        {
                            id : "19-18",
                            type : "unset",
                            time : "16:30 - 17:00",
                            title : 'پنج شنبه | 16:30'
                        },
                        {
                            id : "19-19",
                            type : "unset",
                            time : "17:00 - 17:30",
                            title : 'پنج شنبه | 17:00'
                        },
                        {
                            id : "19-20",
                            type : "unset",
                            time : "17:30 - 18:00",
                            title : 'پنج شنبه | 17:30'
                        },
                        {
                            id : "19-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'پنج شنبه | 18:00'
                        },
                        {
                            id : "19-21",
                            type : "unset",
                            time : "18:30 - 19:00",
                            title : 'پنج شنبه | 18:30'
                        },
                        {
                            id : "19-22",
                            type : "unset",
                            time : "19:00 - 19:30",
                            title : 'پنج شنبه | 19:00'
                        },
                        {
                            id : "19-23",
                            type : "available",
                            time : "19:30 - 20:00",
                            title : 'پنج شنبه | 19:30'
                        },
                        {
                            id : "19-24",
                            type : "available",
                            time : "20:00 - 20:30",
                            title : 'پنج شنبه | 20:00'
                        },
                        {
                            id : "19-25",
                            type : "available",
                            time : "20:30 - 21:00",
                            title : 'پنج شنبه | 20:30'
                        },
                        {
                            id : "19-26",
                            type : "reserved",
                            time : "21:00 - 21:30",
                            title : 'پنج شنبه | 21:00'
                        },
                        {
                            id : "19-27",
                            type : "unset",
                            time : "21:30 - 22:00",
                            title : 'پنج شنبه | 21:30'
                        },
                        {
                            id : "19-28",
                            type : "unset",
                            time : "22:00 - 22:30",
                            title : 'پنج شنبه | 22:00'
                        },
                        {
                            id : "19-29",
                            type : "unset",
                            time : "22:30 - 23:00",
                            title : 'پنج شنبه | 22:30'
                        },
                        {
                            id : "19-30",
                            type : "inProgress",
                            time : "23:00 - 23:30",
                            title : 'پنج شنبه | 23:00'
                        },
                        {
                            id : "19-31",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'پنج شنبه | 23:30'
                        },
                    ]
                },
                {
                    day : 20,
                    title :"جمعه 4",
                    isHoliday : true,
                    isToday : false,
                    times:[
                        {
                            id : "20-1",
                            type : "unset",
                            time : "07:30 - 08:00",
                            title : 'جمعه | 07:30'
                        },
                        {
                            id : "20-2",
                            type : "unset",
                            time : "08:00 - 08:30",
                            title : 'جمعه | 08:00'
                        },
                        {
                            id : "20-3",
                            type : "unset",
                            time : "08:30 - 09:00",
                            title : 'جمعه | 08:30'
                        },
                        {
                            id : "20-4",
                            type : "unset",
                            time : "09:00 - 09:30",
                            title : 'جمعه | 09:00'
                        },
                        {
                            id : "20-5",
                            type : "unset",
                            time : "09:30 - 10:00",
                            title : 'جمعه | 09:30'
                        },
                        {
                            id : "20-6",
                            type : "reserved",
                            time : "10:00 - 10:30",
                            title : 'جمعه | 10:00'
                        },
                        {
                            id : "20-7",
                            type : "unset",
                            time : "10:30 - 11:00",
                            title : 'جمعه | 10:30'
                        },
                        {
                            id : "20-7",
                            type : "unset",
                            time : "11:00 - 11:30",
                            title : 'جمعه | 11:00'
                        },
                        {
                            id : "20-8",
                            type : "unset",
                            time : "11:30 - 12:00",
                            title : 'جمعه | 11:30'
                        },
                        {
                            id : "20-9",
                            type : "unset",
                            time : "12:00 - 12:30",
                            title : 'جمعه | 12:00'
                        },
                        {
                            id : "20-10",
                            type : "unset",
                            time : "12:30 - 13:00",
                            title : 'جمعه | 12:30'
                        },
                        {
                            id : "20-11",
                            type : "unset",
                            time : "13:00 - 13:30",
                            title : 'جمعه | 13:00'
                        },
                        {
                            id : "20-12",
                            type : "unset",
                            time : "13:30 - 14:00",
                            title : 'جمعه | 13:30'
                        },
                        {
                            id : "20-13",
                            type : "inProgress",
                            time : "14:00 - 14:30",
                            title : 'جمعه | 14:00'
                        },
                        {
                            id : "20-14",
                            type : "unset",
                            time : "14:30 - 15:00",
                            title : 'جمعه | 14:30'
                        },
                        {
                            id : "20-15",
                            type : "unset",
                            time : "15:00 - 15:30",
                            title : 'جمعه | 15:00'
                        },
                        {
                            id : "20-16",
                            type : "unset",
                            time : "15:30 - 16:00",
                            title : 'جمعه | 15:30'
                        },
                        {
                            id : "20-17",
                            type : "unset",
                            time : "16:00 - 16:30",
                            title : 'جمعه | 16:00'
                        },
                        {
                            id : "20-18",
                            type : "unset",
                            time : "16:30 - 17:00",
                            title : 'جمعه | 16:30'
                        },
                        {
                            id : "20-19",
                            type : "unset",
                            time : "17:00 - 17:30",
                            title : 'جمعه | 17:00'
                        },
                        {
                            id : "20-20",
                            type : "reserved",
                            time : "17:30 - 18:00",
                            title : 'جمعه | 17:30'
                        },
                        {
                            id : "20-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'جمعه | 18:00'
                        },
                        {
                            id : "20-21",
                            type : "unset",
                            time : "18:30 - 19:00",
                            title : 'جمعه | 18:30'
                        },
                        {
                            id : "20-22",
                            type : "inProgress",
                            time : "19:00 - 19:30",
                            title : 'جمعه | 19:00'
                        },
                        {
                            id : "20-23",
                            type : "unset",
                            time : "19:30 - 20:00",
                            title : 'جمعه | 19:30'
                        },
                        {
                            id : "20-24",
                            type : "unset",
                            time : "20:00 - 20:30",
                            title : 'جمعه | 20:00'
                        },
                        {
                            id : "20-25",
                            type : "reserved",
                            time : "20:30 - 21:00",
                            title : 'جمعه | 20:30'
                        },
                        {
                            id : "20-26",
                            type : "reserved",
                            time : "21:00 - 21:30",
                            title : 'جمعه | 21:00'
                        },
                        {
                            id : "20-27",
                            type : "unset",
                            time : "21:30 - 22:00",
                            title : 'جمعه | 21:30'
                        },
                        {
                            id : "20-28",
                            type : "unset",
                            time : "22:00 - 22:30",
                            title : 'جمعه | 22:00'
                        },
                        {
                            id : "20-29",
                            type : "unset",
                            time : "22:30 - 23:00",
                            title : 'جمعه | 22:30'
                        },
                        {
                            id : "20-30",
                            type : "unset",
                            time : "23:00 - 23:30",
                            title : 'جمعه | 23:00'
                        },
                        {
                            id : "20-26",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'جمعه | 23:30'
                        },
                    ]
                },
                {
                    day : 21,
                    title :"شنبه 5",
                    isHoliday : false,
                    isToday : false,
                    times:[
                        {
                            id : "21-1",
                            type : "unset",
                            time : "07:30 - 08:00",
                            title : 'شنبه | 07:30'
                        },
                        {
                            id : "21-2",
                            type : "unset",
                            time : "08:00 - 08:30",
                            title : 'شنبه | 08:00'
                        },
                        {
                            id : "21-3",
                            type : "reserved",
                            time : "08:30 - 09:00",
                            title : 'شنبه | 08:30'
                        },
                        {
                            id : "21-4",
                            type : "unset",
                            time : "09:00 - 09:30",
                            title : 'شنبه | 09:00'
                        },
                        {
                            id : "21-5",
                            type : "reserved",
                            time : "09:30 - 10:00",
                            title : 'شنبه | 09:30'
                        },
                        {
                            id : "21-6",
                            type : "unset",
                            time : "10:00 - 10:30",
                            title : 'شنبه | 10:00'
                        },
                        {
                            id : "21-7",
                            type : "inProgress",
                            time : "10:30 - 11:00",
                            title : 'شنبه | 10:30'
                        },
                        {
                            id : "21-72",
                            type : "unset",
                            time : "11:00 - 11:30",
                            title : 'شنبه | 11:00'
                        },
                        {
                            id : "21-8",
                            type : "unset",
                            time : "11:30 - 12:00",
                            title : 'شنبه | 11:30'
                        },
                        {
                            id : "21-9",
                            type : "unset",
                            time : "12:00 - 12:30",
                            title : 'شنبه | 12:00'
                        },
                        {
                            id : "21-10",
                            type : "reserved",
                            time : "12:30 - 13:00",
                            title : 'شنبه | 12:30'
                        },
                        {
                            id : "21-11",
                            type : "unset",
                            time : "13:00 - 13:30",
                            title : 'شنبه | 13:00'
                        },
                        {
                            id : "21-12",
                            type : "unset",
                            time : "13:30 - 14:00",
                            title : 'شنبه | 13:30'
                        },
                        {
                            id : "21-13",
                            type : "unset",
                            time : "14:00 - 14:30",
                            title : 'شنبه | 14:00'
                        },
                        {
                            id : "21-14",
                            type : "unset",
                            time : "14:30 - 15:00",
                            title : 'شنبه | 14:30'
                        },
                        {
                            id : "21-15",
                            type : "unset",
                            time : "15:00 - 15:30",
                            title : 'شنبه | 15:00'
                        },
                        {
                            id : "21-16",
                            type : "unset",
                            time : "15:30 - 16:00",
                            title : 'شنبه | 15:30'
                        },
                        {
                            id : "21-17",
                            type : "unset",
                            time : "16:00 - 16:30",
                            title : 'شنبه | 16:00'
                        },
                        {
                            id : "21-18",
                            type : "unset",
                            time : "16:30 - 17:00",
                            title : 'شنبه | 16:30'
                        },
                        {
                            id : "21-19",
                            type : "unset",
                            time : "17:00 - 17:30",
                            title : 'شنبه | 17:00'
                        },
                        {
                            id : "21-20",
                            type : "reserved",
                            time : "17:30 - 18:00",
                            title : 'شنبه | 17:30'
                        },
                        {
                            id : "21-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'شنبه | 18:00'
                        },
                        {
                            id : "21-21",
                            type : "inProgress",
                            time : "18:30 - 19:00",
                            title : 'شنبه | 18:30'
                        },
                        {
                            id : "21-22",
                            type : "unset",
                            time : "19:00 - 19:30",
                            title : 'شنبه | 19:00'
                        },
                        {
                            id : "21-23",
                            type : "unset",
                            time : "19:30 - 20:00",
                            title : 'شنبه | 19:30'
                        },
                        {
                            id : "21-24",
                            type : "unset",
                            time : "20:00 - 20:30",
                            title : 'شنبه | 20:00'
                        },
                        {
                            id : "21-25",
                            type : "unset",
                            time : "20:30 - 21:00",
                            title : 'شنبه | 20:30'
                        },
                        {
                            id : "21-26",
                            type : "unset",
                            time : "21:00 - 21:30",
                            title : 'شنبه | 21:00'
                        },
                        {
                            id : "21-27",
                            type : "unset",
                            time : "21:30 - 22:00",
                            title : 'شنبه | 21:30'
                        },
                        {
                            id : "21-28",
                            type : "unset",
                            time : "22:00 - 22:30",
                            title : 'شنبه | 22:00'
                        },
                        {
                            id : "21-29",
                            type : "unset",
                            time : "22:30 - 23:00",
                            title : 'شنبه | 22:30'
                        },
                        {
                            id : "21-30",
                            type : "reserved",
                            time : "23:00 - 23:30",
                            title : 'شنبه | 23:00'
                        },
                        {
                            id : "21-26",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'شنبه | 23:30'
                        },
                    ]
                },
                {
                    day : 22,
                    title :"یکشنبه 6",
                    isHoliday : false,
                    isToday : false,
                    times:[
                        {
                            id : "22-1",
                            type : "unset",
                            time : "07:30 - 08:00",
                            title : 'یکشنبه | 07:30'
                        },
                        {
                            id : "22-2",
                            type : "unset",
                            time : "08:00 - 08:30",
                            title : 'یکشنبه | 08:00'
                        },
                        {
                            id : "22-3",
                            type : "unset",
                            time : "08:30 - 09:00",
                            title : 'یکشنبه | 08:30'
                        },
                        {
                            id : "22-4",
                            type : "unset",
                            time : "09:00 - 09:30",
                            title : 'یکشنبه | 09:00'
                        },
                        {
                            id : "22-5",
                            type : "unset",
                            time : "09:30 - 10:00",
                            title : 'یکشنبه | 09:30'
                        },
                        {
                            id : "22-6",
                            type : "reserved",
                            time : "10:00 - 10:30",
                            title : 'یکشنبه | 10:00'
                        },
                        {
                            id : "22-7",
                            type : "unset",
                            time : "10:30 - 11:00",
                            title : 'یکشنبه | 10:30'
                        },
                        {
                            id : "22-7",
                            type : "unset",
                            time : "11:00 - 11:30",
                            title : 'یکشنبه | 11:00'
                        },
                        {
                            id : "22-8",
                            type : "unset",
                            time : "11:30 - 12:00",
                            title : 'یکشنبه | 11:30'
                        },
                        {
                            id : "22-9",
                            type : "reserved",
                            time : "12:00 - 12:30",
                            title : 'یکشنبه | 12:00'
                        },
                        {
                            id : "22-10",
                            type : "reserved",
                            time : "12:30 - 13:00",
                            title : 'یکشنبه | 12:30'
                        },
                        {
                            id : "22-11",
                            type : "reserved",
                            time : "13:00 - 13:30",
                            title : 'یکشنبه | 13:00'
                        },
                        {
                            id : "22-12",
                            type : "unset",
                            time : "13:30 - 14:00",
                            title : 'یکشنبه | 13:30'
                        },
                        {
                            id : "22-13",
                            type : "inProgress",
                            time : "14:00 - 14:30",
                            title : 'یکشنبه | 14:00'
                        },
                        {
                            id : "22-14",
                            type : "unset",
                            time : "14:30 - 15:00",
                            title : 'یکشنبه | 14:30'
                        },
                        {
                            id : "22-15",
                            type : "unset",
                            time : "15:00 - 15:30",
                            title : 'یکشنبه | 15:00'
                        },
                        {
                            id : "22-16",
                            type : "unset",
                            time : "15:30 - 16:00",
                            title : 'یکشنبه | 15:30'
                        },
                        {
                            id : "22-17",
                            type : "reserved",
                            time : "16:00 - 16:30",
                            title : 'یکشنبه | 16:00'
                        },
                        {
                            id : "22-18",
                            type : "reserved",
                            time : "16:30 - 17:00",
                            title : 'یکشنبه | 16:30'
                        },
                        {
                            id : "22-19",
                            type : "reserved",
                            time : "17:00 - 17:30",
                            title : 'یکشنبه | 17:00'
                        },
                        {
                            id : "22-20",
                            type : "unset",
                            time : "17:30 - 18:00",
                            title : 'یکشنبه | 17:30'
                        },
                        {
                            id : "22-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'یکشنبه | 18:00'
                        },
                        {
                            id : "22-21",
                            type : "unset",
                            time : "18:30 - 19:00",
                            title : 'یکشنبه | 18:30'
                        },
                        {
                            id : "22-22",
                            type : "unset",
                            time : "19:00 - 19:30",
                            title : 'یکشنبه | 19:00'
                        },
                        {
                            id : "22-23",
                            type : "unset",
                            time : "19:30 - 20:00",
                            title : 'یکشنبه | 19:30'
                        },
                        {
                            id : "22-24",
                            type : "inProgress",
                            time : "20:00 - 20:30",
                            title : 'یکشنبه | 20:00'
                        },
                        {
                            id : "22-25",
                            type : "unset",
                            time : "20:30 - 21:00",
                            title : 'یکشنبه | 20:30'
                        },
                        {
                            id : "22-26",
                            type : "unset",
                            time : "21:00 - 21:30",
                            title : 'یکشنبه | 21:00'
                        },
                        {
                            id : "22-27",
                            type : "unset",
                            time : "21:30 - 22:00",
                            title : 'یکشنبه | 21:30'
                        },
                        {
                            id : "22-28",
                            type : "unset",
                            time : "22:00 - 22:30",
                            title : 'یکشنبه | 22:00'
                        },
                        {
                            id : "22-29",
                            type : "unset",
                            time : "22:30 - 23:00",
                            title : 'یکشنبه | 22:30'
                        },
                        {
                            id : "22-30",
                            type : "unset",
                            time : "23:00 - 23:30",
                            title : 'یکشنبه | 23:00'
                        },
                        {
                            id : "22-26",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'یکشنبه | 23:30'
                        },
                    ]
                },
                {
                    day : 23,
                    title :"دوشنبه 7",
                    isHoliday : false,
                    isToday : false,
                    times:[
                        {
                            id : "23-1",
                            type : "unset",
                            time : "07:30 - 08:00",
                            title : 'دوشنبه | 07:30'
                        },
                        {
                            id : "23-2",
                            type : "unset",
                            time : "08:00 - 08:30",
                            title : 'دوشنبه | 08:00'
                        },
                        {
                            id : "23-3",
                            type : "inProgress",
                            time : "08:30 - 09:00",
                            title : 'دوشنبه | 08:30'
                        },
                        {
                            id : "23-4",
                            type : "reserved",
                            time : "09:00 - 09:30",
                            title : 'دوشنبه | 09:00'
                        },
                        {
                            id : "23-5",
                            type : "reserved",
                            time : "09:30 - 10:00",
                            title : 'دوشنبه | 09:30'
                        },
                        {
                            id : "23-6",
                            type : "reserved",
                            time : "10:00 - 10:30",
                            title : 'دوشنبه | 10:00'
                        },
                        {
                            id : "23-7",
                            type : "unset",
                            time : "10:30 - 11:00",
                            title : 'دوشنبه | 10:30'
                        },
                        {
                            id : "23-7",
                            type : "unset",
                            time : "11:00 - 11:30",
                            title : 'دوشنبه | 11:00'
                        },
                        {
                            id : "23-8",
                            type : "unset",
                            time : "11:30 - 12:00",
                            title : 'دوشنبه | 11:30'
                        },
                        {
                            id : "23-9",
                            type : "unset",
                            time : "12:00 - 12:30",
                            title : 'دوشنبه | 12:00'
                        },
                        {
                            id : "23-10",
                            type : "available",
                            time : "12:30 - 13:00",
                            title : 'دوشنبه | 12:30'
                        },
                        {
                            id : "23-11",
                            type : "available",
                            time : "13:00 - 13:30",
                            title : 'دوشنبه | 13:00'
                        },
                        {
                            id : "23-12",
                            type : "reserved",
                            time : "13:30 - 14:00",
                            title : 'دوشنبه | 13:30'
                        },
                        {
                            id : "23-13",
                            type : "available",
                            time : "14:00 - 14:30",
                            title : 'دوشنبه | 14:00'
                        },
                        {
                            id : "23-14",
                            type : "available",
                            time : "14:30 - 15:00",
                            title : 'دوشنبه | 14:30'
                        },
                        {
                            id : "23-15",
                            type : "unset",
                            time : "15:00 - 15:30",
                            title : 'دوشنبه | 15:00'
                        },
                        {
                            id : "23-16",
                            type : "unset",
                            time : "15:30 - 16:00",
                            title : 'دوشنبه | 15:30'
                        },
                        {
                            id : "23-17",
                            type : "unset",
                            time : "16:00 - 16:30",
                            title : 'دوشنبه | 16:00'
                        },
                        {
                            id : "23-18",
                            type : "reserved",
                            time : "16:30 - 17:00",
                            title : 'دوشنبه | 16:30'
                        },
                        {
                            id : "23-19",
                            type : "reserved",
                            time : "17:00 - 17:30",
                            title : 'دوشنبه | 17:00'
                        },
                        {
                            id : "23-20",
                            type : "unset",
                            time : "17:30 - 18:00",
                            title : 'دوشنبه | 17:30'
                        },
                        {
                            id : "23-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'دوشنبه | 18:00'
                        },
                        {
                            id : "23-21",
                            type : "inProgress",
                            time : "18:30 - 19:00",
                            title : 'دوشنبه | 18:30'
                        },
                        {
                            id : "23-22",
                            type : "unset",
                            time : "19:00 - 19:30",
                            title : 'دوشنبه | 19:00'
                        },
                        {
                            id : "23-23",
                            type : "unset",
                            time : "19:30 - 20:00",
                            title : 'دوشنبه | 19:30'
                        },
                        {
                            id : "23-24",
                            type : "unset",
                            time : "20:00 - 20:30",
                            title : 'دوشنبه | 20:00'
                        },
                        {
                            id : "23-25",
                            type : "unset",
                            time : "20:30 - 21:00",
                            title : 'دوشنبه | 20:30'
                        },
                        {
                            id : "23-26",
                            type : "unset",
                            time : "21:00 - 21:30",
                            title : 'دوشنبه | 21:00'
                        },
                        {
                            id : "23-27",
                            type : "reserved",
                            time : "21:30 - 22:00",
                            title : 'دوشنبه | 21:30'
                        },
                        {
                            id : "23-28",
                            type : "unset",
                            time : "22:00 - 22:30",
                            title : 'دوشنبه | 22:00'
                        },
                        {
                            id : "23-29",
                            type : "unset",
                            time : "22:30 - 23:00",
                            title : 'دوشنبه | 22:30'
                        },
                        {
                            id : "23-30",
                            type : "unset",
                            time : "23:00 - 23:30",
                            title : 'دوشنبه | 23:00'
                        },
                        {
                            id : "23-26",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'دوشنبه | 23:30'
                        },
                    ]
                },
                {
                    day : 24,
                    title :"سه‌شنبه 8",
                    isHoliday : false,
                    isToday : false,
                    times:[
                        {
                            id : "25-1",
                            type : "unset",
                            time : "07:30 - 08:00",
                            title : 'سه‌شنبه | 07:30'
                        },
                        {
                            id : "25-2",
                            type : "unset",
                            time : "08:00 - 08:30",
                            title : 'سه‌شنبه | 08:00'
                        },
                        {
                            id : "25-3",
                            type : "inProgress",
                            time : "08:30 - 09:00",
                            title : 'سه‌شنبه | 08:30'
                        },
                        {
                            id : "25-4",
                            type : "unset",
                            time : "09:00 - 09:30",
                            title : 'سه‌شنبه | 09:00'
                        },
                        {
                            id : "25-5",
                            type : "reserved",
                            time : "09:30 - 10:00",
                            title : 'سه‌شنبه | 09:30'
                        },
                        {
                            id : "25-6",
                            type : "unset",
                            time : "10:00 - 10:30",
                            title : 'سه‌شنبه | 10:00'
                        },
                        {
                            id : "25-7",
                            type : "unset",
                            time : "10:30 - 11:00",
                            title : 'سه‌شنبه | 10:30'
                        },
                        {
                            id : "25-7",
                            type : "unset",
                            time : "11:00 - 11:30",
                            title : 'سه‌شنبه | 11:00'
                        },
                        {
                            id : "25-8",
                            type : "available",
                            time : "11:30 - 12:00",
                            title : 'سه‌شنبه | 11:30'
                        },
                        {
                            id : "25-9",
                            type : "available",
                            time : "12:00 - 12:30",
                            title : 'سه‌شنبه | 12:00'
                        },
                        {
                            id : "25-10",
                            type : "available",
                            time : "12:30 - 13:00",
                            title : 'سه‌شنبه | 12:30'
                        },
                        {
                            id : "25-11",
                            type : "available",
                            time : "13:00 - 13:30",
                            title : 'سه‌شنبه | 13:00'
                        },
                        {
                            id : "25-12",
                            type : "available",
                            time : "13:30 - 14:00",
                            title : 'سه‌شنبه | 13:30'
                        },
                        {
                            id : "25-13",
                            type : "available",
                            time : "14:00 - 14:30",
                            title : 'سه‌شنبه | 14:00'
                        },
                        {
                            id : "25-14",
                            type : "available",
                            time : "14:30 - 15:00",
                            title : 'سه‌شنبه | 14:30'
                        },
                        {
                            id : "25-15",
                            type : "available",
                            time : "15:00 - 15:30",
                            title : 'سه‌شنبه | 15:00'
                        },
                        {
                            id : "25-16",
                            type : "available",
                            time : "15:30 - 16:00",
                            title : 'سه‌شنبه | 15:30'
                        },
                        {
                            id : "25-17",
                            type : "reserved",
                            time : "16:00 - 16:30",
                            title : 'سه‌شنبه | 16:00'
                        },
                        {
                            id : "25-18",
                            type : "available",
                            time : "16:30 - 17:00",
                            title : 'سه‌شنبه | 16:30'
                        },
                        {
                            id : "25-19",
                            type : "unset",
                            time : "17:00 - 17:30",
                            title : 'سه‌شنبه | 17:00'
                        },
                        {
                            id : "25-20",
                            type : "unset",
                            time : "17:30 - 18:00",
                            title : 'سه‌شنبه | 17:30'
                        },
                        {
                            id : "25-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'سه‌شنبه | 18:00'
                        },
                        {
                            id : "25-21",
                            type : "unset",
                            time : "18:30 - 19:00",
                            title : 'سه‌شنبه | 18:30'
                        },
                        {
                            id : "25-22",
                            type : "unset",
                            time : "19:00 - 19:30",
                            title : 'سه‌شنبه | 19:00'
                        },
                        {
                            id : "25-23",
                            type : "reserved",
                            time : "19:30 - 20:00",
                            title : 'سه‌شنبه | 19:30'
                        },
                        {
                            id : "25-24",
                            type : "unset",
                            time : "20:00 - 20:30",
                            title : 'سه‌شنبه | 20:00'
                        },
                        {
                            id : "25-25",
                            type : "unset",
                            time : "20:30 - 21:00",
                            title : 'سه‌شنبه | 20:30'
                        },
                        {
                            id : "25-26",
                            type : "unset",
                            time : "21:00 - 21:30",
                            title : 'سه‌شنبه | 21:00'
                        },
                        {
                            id : "25-27",
                            type : "inProgress",
                            time : "21:30 - 22:00",
                            title : 'سه‌شنبه | 21:30'
                        },
                        {
                            id : "25-28",
                            type : "unset",
                            time : "22:00 - 22:30",
                            title : 'سه‌شنبه | 22:00'
                        },
                        {
                            id : "25-29",
                            type : "reserved",
                            time : "22:30 - 23:00",
                            title : 'سه‌شنبه | 22:30'
                        },
                        {
                            id : "25-30",
                            type : "unset",
                            time : "23:00 - 23:30",
                            title : 'سه‌شنبه | 23:00'
                        },
                        {
                            id : "25-26",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'سه‌شنبه | 23:30'
                        },
                    ]
                },
                {
                    day : 26,
                    title :"چهارشنبه 9",
                    isHoliday : false,
                    isToday : true,
                    times:[
                        {
                            id : "26-1",
                            type : "unset",
                            time : "07:30 - 08:00",
                            title : 'چهارشنبه | 07:30'
                        },
                        {
                            id : "26-2",
                            type : "unset",
                            time : "08:00 - 08:30",
                            title : 'چهارشنبه | 08:00'
                        },
                        {
                            id : "26-3",
                            type : "unset",
                            time : "08:30 - 09:00",
                            title : 'چهارشنبه | 08:30'
                        },
                        {
                            id : "26-4",
                            type : "reserved",
                            time : "09:00 - 09:30",
                            title : 'چهارشنبه | 09:00'
                        },
                        {
                            id : "26-5",
                            type : "reserved",
                            time : "09:30 - 10:00",
                            title : 'چهارشنبه | 09:30'
                        },
                        {
                            id : "26-6",
                            type : "reserved",
                            time : "10:00 - 10:30",
                            title : 'چهارشنبه | 10:00'
                        },
                        {
                            id : "26-7",
                            type : "reserved",
                            time : "10:30 - 11:00",
                            title : 'چهارشنبه | 10:30'
                        },
                        {
                            id : "26-7",
                            type : "reserved",
                            time : "11:00 - 11:30",
                            title : 'چهارشنبه | 11:00'
                        },
                        {
                            id : "26-8",
                            type : "reserved",
                            time : "11:30 - 12:00",
                            title : 'چهارشنبه | 11:30'
                        },
                        {
                            id : "26-9",
                            type : "available",
                            time : "12:00 - 12:30",
                            title : 'چهارشنبه | 12:00'
                        },
                        {
                            id : "26-10",
                            type : "available",
                            time : "12:30 - 13:00",
                            title : 'چهارشنبه | 12:30'
                        },
                        {
                            id : "26-11",
                            type : "available",
                            time : "13:00 - 13:30",
                            title : 'چهارشنبه | 13:00'
                        },
                        {
                            id : "26-12",
                            type : "available",
                            time : "13:30 - 14:00",
                            title : 'چهارشنبه | 13:30'
                        },
                        {
                            id : "26-13",
                            type : "available",
                            time : "14:00 - 14:30",
                            title : 'چهارشنبه | 14:00'
                        },
                        {
                            id : "26-14",
                            type : "unset",
                            time : "14:30 - 15:00",
                            title : 'چهارشنبه | 14:30'
                        },
                        {
                            id : "26-15",
                            type : "unset",
                            time : "15:00 - 15:30",
                            title : 'چهارشنبه | 15:00'
                        },
                        {
                            id : "26-16",
                            type : "unset",
                            time : "15:30 - 16:00",
                            title : 'چهارشنبه | 15:30'
                        },
                        {
                            id : "26-17",
                            type : "unset",
                            time : "16:00 - 16:30",
                            title : 'چهارشنبه | 16:00'
                        },
                        {
                            id : "26-18",
                            type : "unset",
                            time : "16:30 - 17:00",
                            title : 'چهارشنبه | 16:30'
                        },
                        {
                            id : "26-19",
                            type : "reserved",
                            time : "17:00 - 17:30",
                            title : 'چهارشنبه | 17:00'
                        },
                        {
                            id : "26-20",
                            type : "unset",
                            time : "17:30 - 18:00",
                            title : 'چهارشنبه | 17:30'
                        },
                        {
                            id : "26-20",
                            type : "unset",
                            time : "18:00 - 18:30",
                            title : 'چهارشنبه | 18:00'
                        },
                        {
                            id : "26-21",
                            type : "unset",
                            time : "18:30 - 19:00",
                            title : 'چهارشنبه | 18:30'
                        },
                        {
                            id : "26-22",
                            type : "unset",
                            time : "19:00 - 19:30",
                            title : 'چهارشنبه | 19:00'
                        },
                        {
                            id : "26-23",
                            type : "unset",
                            time : "19:30 - 20:00",
                            title : 'چهارشنبه | 19:30'
                        },
                        {
                            id : "26-24",
                            type : "unset",
                            time : "20:00 - 20:30",
                            title : 'چهارشنبه | 20:00'
                        },
                        {
                            id : "26-25",
                            type : "unset",
                            time : "20:30 - 21:00",
                            title : 'چهارشنبه | 20:30'
                        },
                        {
                            id : "26-26",
                            type : "unset",
                            time : "21:00 - 21:30",
                            title : 'چهارشنبه | 21:00'
                        },
                        {
                            id : "26-27",
                            type : "unset",
                            time : "21:30 - 22:00",
                            title : 'چهارشنبه | 21:30'
                        },
                        {
                            id : "26-28",
                            type : "unset",
                            time : "22:00 - 22:30",
                            title : 'چهارشنبه | 22:00'
                        },
                        {
                            id : "26-29",
                            type : "unset",
                            time : "22:30 - 23:00",
                            title : 'چهارشنبه | 22:30'
                        },
                        {
                            id : "26-30",
                            type : "unset",
                            time : "23:00 - 23:30",
                            title : 'چهارشنبه | 23:00'
                        },
                        {
                            id : "26-26",
                            type : "unset",
                            time : "23:30 - 00:00",
                            title : 'چهارشنبه | 23:30'
                        },
                    ]
                }
            ],
            page : 0
        },
    ];
    useEffect(() => {
        if(!params.id || !params.name){
            navigate("/");
        }
        scrollToTop();
        setTimeout(() => {
            fetchData();
        }, 3000);
    }, []);
    function fetchData(){
        setIsLoading(false);
        setData(tempData[tempCounter.current]);
    }
    function clickOnItem(clickedItem){
        let temp = {...reservedTimes};
        if(reservedTimes[clickedItem.id]){
            delete temp[clickedItem.id];
        }else{
            if(Object.keys(temp).length >= 10){
                showModal("بیش از 10 کلاس نمی توانید انتخاب کنید.");
                return;
            }
            temp[clickedItem.id] = clickedItem;
        }
        setReservedTimes(temp);
    }
    function nextPage(counter){
        tempCounter.current = tempCounter.current + counter;
        setIsLoading(true);
        setTimeout(() => {
           fetchData();
        }, 3000);
    }
    return <>
        <NavsList data={[{name:params.name,link:`/teacher/${params.id}`},{name:`رزرو کلاس خصوصی استاد ${params.name}`,link:""}]} />
        <section className="hintReserveDiv flex container">
            <div className="flex alignCenter hintItem">
                <div className="hintIcon rel choiced"></div>
                <p className="faFont textSize9 textClr3">
                    انتخاب شده
                </p>
            </div>
            <div className="flex alignCenter hintItem">
                <div className="hintIcon rel reserved"></div>
                <p className="faFont textSize9 textClr3">
                    رزرو شده
                </p>
            </div>
            <div className="flex alignCenter hintItem">
                <div className="hintIcon rel inProgress"></div>
                <p className="faFont textSize9 textClr3">
                    در حال رزرو
                </p>
            </div>
            <div className="flex alignCenter hintItem">
                <div className="hintIcon rel available"></div>
                <p className="faFont textSize9 textClr3">
                    قابل رزرو
                </p>
            </div>
        </section>
        <main className="grid container reserveMainGrid">
            <section className="flex flexCol reserveMainSection">
                <div className="grid reserveWeekGrid">
                    <button className={`flex flexCenter prevWeek textClr5 ${!data.week.hasPrevWeek ? "disable" : ""}`} onClick={()=>nextPage(-1)}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="22" width="22" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path></svg>
                        <span className="hideText"></span>
                    </button>
                    <p className="faFont textSize9 textClr5 flex flexCenter">
                    {
                        data.week.title
                    }
                    </p>
                    <button className={`flex flexCenter nextWeek textClr5 ${!data.week.hasNextWeek ? "disable" : ""}`} onClick={()=>nextPage(1)}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="22" width="22" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>
                        <span className="hideText"></span>
                    </button>
                </div>
                <div className="grid weekDayNameMainDiv">
                    <div></div>
                    <div className="weekDayNameDiv grid">
                        <p className="faFont weekDayNameText"></p>
                    </div>
                </div>
                <div className="grid reserveCalendarMainDiv rel">
                    <div className="flex flexCol calendarTimeList first">
                    <p className="faFont dayNameText">
                        
                    </p>
                    <div className="flex flexCol">
                        {
                            dayTimes.map((item,index)=>{
                                return <p className="faFont dayTimeText flex flexCenter" key={index}>
                                    {
                                        item
                                    }
                                </p>
                            })
                        }
                    </div>
                    </div>
                    {
                        data.days.map((item,index)=>{
                            return <div className={`flex flexCol calendarTimeList ${index === 0 ? "firstRow" : ""}`} key={index}>
                                <p className={`faFont dayNameText ${item.isHoliday ? "holiday" : ""} ${item.isToday ? "today" : ""}`}>
                                    {
                                        item.title
                                    }
                                </p>
                                <div className="flex flexCol">
                                    {
                                        item.times.map((item,index)=>{
                                            return <div className={`faFont dayTimeText flex flexCenter rel ${item.type === "unset" && "inactiveDay"} ${item.type} ${reservedTimes[item.id] ? "choiced" : ""}`} onClick={()=>{if(item.type === "available") clickOnItem(item)} } key={index}>
                                            <div className="flex flexCol daySubTimeText rel">
                                                    <img src="/image/reserved.webp" alt="reserve" className="reservedImg" draggable="false" />
                                            </div>
                                                <p className="faFont textSize8 textClrF itemHoverText">
                                                    <span>
                                                    {
                                                        item.title 
                                                    }
                                                    </span>
                                                    {
                                                        item.type === "reserved" && <span>رزرو شده</span>
                                                    }
                                                    {
                                                        item.type === "inProgress" && <span>در حال رزرو</span>
                                                    }
                                                    {
                                                        reservedTimes[item.id] && <span>انتخاب شده</span>
                                                    }
                                                </p>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                    {
                        data.firstLoad && <div className="loadingCalendar flex flexCol rel">
                            <div className="loaderItem"></div>
                        </div>
                    }
                    {
                        isLoading && <div className="loadingNextCalendar flex flexCol flexCenter rel">
                            <div className="loadingNextPageSpin"></div>
                        </div>
                    }
                </div>
            </section>
            <section className="resultReserveSection flex flexCol">
                <h4 className="faFont textSize12 textClr3 flex alignCenter resultReserveTitle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.1" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M14.341 3.579c-0.347-0.473-0.831-1.027-1.362-1.558s-1.085-1.015-1.558-1.362c-0.806-0.591-1.197-0.659-1.421-0.659h-7.75c-0.689 0-1.25 0.561-1.25 1.25v13.5c0 0.689 0.561 1.25 1.25 1.25h11.5c0.689 0 1.25-0.561 1.25-1.25v-9.75c0-0.224-0.068-0.615-0.659-1.421zM12.271 2.729c0.48 0.48 0.856 0.912 1.134 1.271h-2.406v-2.405c0.359 0.278 0.792 0.654 1.271 1.134zM14 14.75c0 0.136-0.114 0.25-0.25 0.25h-11.5c-0.135 0-0.25-0.114-0.25-0.25v-13.5c0-0.135 0.115-0.25 0.25-0.25 0 0 7.749-0 7.75 0v3.5c0 0.276 0.224 0.5 0.5 0.5h3.5v9.75z"></path><path d="M11.5 13h-7c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h7c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path><path d="M11.5 11h-7c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h7c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path><path d="M11.5 9h-7c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h7c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path></svg>
                    زمان های رزرو شده
                </h4>
                {
                    Object.keys(reservedTimes).length > 0 ? <div className="flex reserveList">
                        {
                            Object.keys(reservedTimes).map((item,index)=>{
                                return <div className="flex alignCenter reserveItem" key={index}>
                                    <p className="faFont textSize9 textClr3 reserveItemText">
                                        {
                                            reservedTimes[item].time
                                        }
                                    </p>
                                    <button className="flex flexCenter removeReserveTime" onClick={()=>clickOnItem(reservedTimes[item])}>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path></svg>
                                        <span className="hideText">remve</span>
                                    </button>
                                </div>
                            })
                        }
                    </div> : 
                    <p className="faFont textSize1 textClr5 noReserveTime flex alignCenter">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                        زمانی انتخاب نشده است.
                    </p>
                }
                <button className={`faFont textSize1 textClrF confirmReserve flex flexCenter ${Object.keys(reservedTimes).length === 0 ? "disable" : ""}`}>
                    تایید و ادامه
                </button>
            </section>
        </main>
    </>
}