import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Style/header.css";
import { AppContext } from '../App';
export default function Header(){
    const [showNav , setShowNav] = useState(false);
    const [toggleExpandList,setToggleExpandList] = useState(false);
    const [subToggleMenu,setSubToggleMenu] = useState({one:false,two:false,three:false});
    const {userData} = useContext(AppContext);
    const [userInfo, setUserInfo] = userData;
    const [data,setData] = useState({socialMedia : null , categories : null ,phone1 :""});
    const navigate = useNavigate();
    let preLink = "https://web.bamamiresi.com/";
    const firstLoad = useRef(true);
    useEffect(() => {
        if(firstLoad.current){
            firstLoad.current = false;
            const data = new FormData();
            data.append("name", "shanjan");
            data.append("password", "1234567890");
            fetch("https://web.bamamiresi.com/Enrollment/GetHeader",{
                method:"POST",
                body:data,
            })
            .then((res) => {
                if (!res.ok) {
                throw new Error("102");
                }
                return res.json();
            })
            .then((json) => {
                if (json.error) {
                    throw new Error(json.msg);
                }
                setData(json[0]);
            })
            .catch((err) => {
            });
        }
    }, []);
    return <>
        {
            data.minTopImage && <a href={data.topImageUrl} className="flex flexCol headerTopLink" target="_blank" rel="noopener noreferrer" >
                <img src={preLink + data.topImage} alt="headerImg" className="headerTopLargeImage"/>
                <img src={preLink + data.minTopImage} alt="headerImg" className="headerTopMinImage"/>
            </a>
        }
        <div className="flex flexCol headerCallDiv">
            <div className="flex alignCenter container headerCallContainer">
                <p className="faFont textSize1 textClr3 addressHeaderText flex alignCenter gap10">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 14c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"></path><path d="M11.42 21.814a.998.998 0 0 0 1.16 0C12.884 21.599 20.029 16.44 20 10c0-4.411-3.589-8-8-8S4 5.589 4 9.995c-.029 6.445 7.116 11.604 7.42 11.819zM12 4c3.309 0 6 2.691 6 6.005.021 4.438-4.388 8.423-6 9.73-1.611-1.308-6.021-5.294-6-9.735 0-3.309 2.691-6 6-6z"></path></svg>
                    آدرس : {
                        data.Address ? data.Address : ""
                    }
                </p>
                <div className="flex alginCenter headerCallLeftDiv">
                    <a href={"tel:+" + data.phone1} className="headerCallItem flex alignCenter faFont textSize1 textClr5">
                        <span>{data.phone1}</span>
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
                    </a>
                </div>
            </div>
        </div>
        <header className="flex flexCol">
            <div className="flex alignCenter justifyBetween flexMainDiv container rel">
                <Link className="faFont textSize12 textClr3 flex alignCenter headerMainLogo" to="/">
                   <img src="/image/headerLogo.webp" alt="bamamiresi" className="headerMainLogoImg" height={40} />
                </Link>
                <div className="flex alignCenter headerDeskDiv">
                <div className="flex flexCol headerDropDiv">
                        <p className="faFont headerDeskLink textClr3 textSize8 flex alignCenter">
                            آموزشگاه ها
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                        </p>
                        <div className="flex flexCol headerDropSubMainDiv">
                            <div className="flex flexCol headerDropSubDiv rel">
                               <div className="flex flexCol schoolsList">
                                    <Link to="" className="faFont headerDropSubLink flex textSize9 textClr5">
                                        آموزشگاه ولیعصر
                                    </Link>
                                    <Link to="" className="faFont headerDropSubLink flex textSize9 textClr5">
                                        آموزشگاه سعادت اباد
                                    </Link>
                                    <Link to="" className="faFont headerDropSubLink flex textSize9 textClr5">
                                        آموزشگاه الهیه
                                    </Link>
                                    <Link to="" className="faFont headerDropSubLink flex textSize9 textClr5">
                                        آموزشگاه پیروزی
                                    </Link>
                                    <Link to="" className="faFont headerDropSubLink flex textSize9 textClr5">
                                        آموزشگاه ستارخان
                                    </Link>
                               </div>
                            </div>
                        </div>
                    </div>
                    <Link to="/teacher" className="faFont headerDeskLink textClr3 textSize8">
                        اساتید
                    </Link>
                    <div className="flex flexCol headerDropDiv">
                        <Link to="/courses" className="faFont headerDeskLink textClr3 textSize8 flex alignCenter">
                            دوره ها
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                        </Link>
                        <div className="flex flexCol headerDropSubMainDiv">
                            <div className="flex flexCol headerDropSubDiv rel">
                                <div className="flex flexCol headerItemParentDiv active">
                                    <button className="faFont headerDropShowMenuBtn textSize9 textClr3 flex alignCenter spaceBetween">
                                        موسیقی
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>
                                    </button>
                                    <div className="headerMegaMenuLeftDiv grid row3">
                                        <div className="flex flexCol headerMegaMenuRow">
                                            <Link to="/course/سازهای_ایرانی" className="faFont headerDropSubTitleLink flex textSize9 textClr5">
                                                سازهای ایرانی
                                            </Link>
                                            <div className="flex flexCol headerMegaMenuSubRow">
                                                <Link to="/course/تار" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    تار
                                                </Link>
                                                <Link to="/course/سه_تار" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    سه تار
                                                </Link>
                                                <Link to="/course/سنتور" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    سنتور
                                                </Link>
                                                <Link to="/course/تنبک" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    تنبک
                                                </Link>
                                                <Link to="/course/دف" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    دف
                                                </Link>
                                                <Link to="/course/تنبور" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    تنبور
                                                </Link>
                                                <Link to="/course/عود" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    عود
                                                </Link>
                                                <Link to="/course/دوتار" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    دوتار
                                                </Link>
                                                <Link to="/course/نی_انبان" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    نی انبان
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="flex flexCol headerMegaMenuRow">
                                            <Link to="/course/سازهای_جهانی" className="faFont headerDropSubTitleLink flex textSize9 textClr5">
                                                سازهای جهانی
                                            </Link>
                                            <div className="flex flexCol headerMegaMenuSubRow">
                                                <Link to="/course/پیانو" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    پیانو
                                                </Link>
                                                <Link to="/course/ویولن" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    ویولن
                                                </Link>
                                                <Link to="/course/ویولن_سل " className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    ویولن سل 
                                                </Link>
                                                <Link to="/course/کنترباس" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    کنترباس
                                                </Link>
                                                <Link to="/course/هنگ_درام" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    هنگ درام
                                                </Link>
                                                <Link to="/course/گیتار" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    گیتار
                                                </Link>
                                                <Link to="/course/گیتار_الکتریک" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    گیتار الکتریک
                                                </Link>
                                                <Link to="/course/گیتار_باس" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    گیتار باس
                                                </Link>
                                                <Link to="/course/هارمونیکا" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    هارمونیکا
                                                </Link>
                                                <Link to="/course/چنگ" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    چنگ
                                                </Link>
                                                <Link to="/course/قانون" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    قانون
                                                </Link>
                                                <Link to="/course/ترومپت" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    ترومپت
                                                </Link>
                                                <Link to="/course/بلز" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    بلز
                                                </Link>
                                                <Link to="/course/فلوت_ریکوردر" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    فلوت ریکوردر
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="flex flexCol headerMegaMenuRow">
                                            <Link to="/course/آواز" className="faFont headerDropSubTitleLink flex textSize9 textClr5">
                                                آواز
                                            </Link>
                                            <div className="flex flexCol headerMegaMenuSubRow">
                                                <Link to="/course/آواز_ایرانی" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    آواز ایرانی
                                                </Link>
                                                <Link to="/course/آواز_پاپ" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    آواز پاپ
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flexCol headerItemParentDiv">
                                    <button className="faFont headerDropShowMenuBtn textSize9 textClr3 flex alignCenter spaceBetween">
                                        هنرهای نمایشی 
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>
                                    </button>
                                    <div className="headerMegaMenuLeftDiv grid row1">
                                        <div className="flex flexCol headerMegaMenuRow">
                                            <div className="flex flexCol headerMegaMenuSubRow">
                                                <Link to="/course/نویسندگی_برای_رادیو" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    نویسندگی برای رادیو
                                                </Link>
                                                <Link to="/course/فیلمنامه_نویسی" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    فیلمنامه نویسی 
                                                </Link>
                                                <Link to="/course/نمایشنامه_نویسی" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    نمایشنامه نویسی
                                                </Link>
                                                <Link to="/course/کارگردانی_سینما" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    کارگردانی سینما
                                                </Link>
                                                <Link to="/course/طراحی_و_دوخت لباس_ومد" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    طراحی و دوخت لباس ومد
                                                </Link>
                                                <Link to="/course/گریم" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    گریم
                                                </Link>
                                                <Link to="/course/فیلمبرداری" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    فیلمبرداری
                                                </Link>
                                                <Link to="/course/تدوین" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    تدوین
                                                </Link>
                                                <Link to="/course/بازیگری" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    بازیگری
                                                </Link>
                                                <Link to="/course/بازیگری_سینما" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    بازیگری سینما
                                                </Link>
                                                <Link to="/course/بازیگری_برای_میکروفون" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    بازیگری برای میکروفون
                                                </Link>
                                                <Link to="/course/دوبالژ" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    دوبالژ
                                                </Link>
                                                <Link to="/course/عروسک_سازی" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    عروسک سازی
                                                </Link>
                                                <Link to="/course/افکتور_رادیو" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    فولی آرتیست ( افکتور رادیو و فیلم)
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flexCol headerItemParentDiv">
                                    <button className="faFont headerDropShowMenuBtn textSize9 textClr3 flex alignCenter spaceBetween">
                                        هنرهای تجسمی 
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>
                                    </button>
                                    <div className="headerMegaMenuLeftDiv grid row1">
                                        <div className="flex flexCol headerMegaMenuRow">
                                            <div className="flex flexCol headerMegaMenuSubRow">
                                                <Link to="/course/نقاشی" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    نقاشی
                                                </Link>
                                                <Link to="/course/سفالگری" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    سفالگری
                                                </Link>
                                                <Link to="/course/مجسمه_سازی" className="faFont headerDropSubLink flex textSize9 textClr5">
                                                    مجسمه سازی
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to="/blog" className="faFont headerDeskLink textClr3 textSize8">
                        مقالات
                    </Link>
                    <Link to="/about" className="faFont headerDeskLink textClr3 textSize8">
                        درباره ما
                    </Link>
                    <Link to="/contact" className="faFont headerDeskLink textClr3 textSize8">
                        تماس با ما
                    </Link>
                </div>
                <div className="flex alignCenter gap10 headerRightDiv">
                    <div className="flex flexCol rel cartMainHoverDiv">
                        <Link className="cartBtn flex flexCenter" to="cart">
                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z"></path><path d="M3 6h18"></path><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                            <span className="hideText">cart</span>
                        </Link>
                        <div className="flex flexCol cartHoverDiv">
                            <div className="flex flexCol cartHoverSubDiv">
                                <div className="flex flexCol emptyCartDiv customScrollbar">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M232,72l-25.63,92.28A16,16,0,0,1,191,176H92.16a16,16,0,0,1-15.41-11.72L51.11,72Z" opacity="0.2"></path><path d="M104,216a16,16,0,1,1-16-16A16,16,0,0,1,104,216Zm88-16a16,16,0,1,0,16,16A16,16,0,0,0,192,200ZM239.71,74.14l-25.64,92.28A24.06,24.06,0,0,1,191,184H92.16A24.06,24.06,0,0,1,69,166.42L33.92,40H16a8,8,0,0,1,0-16H40a8,8,0,0,1,7.71,5.86L57.19,64H232a8,8,0,0,1,7.71,10.14ZM221.47,80H61.64l22.81,82.14A8,8,0,0,0,92.16,168H191a8,8,0,0,0,7.71-5.86Z"></path></svg>
                                    <p className="faFont textSize1 textClr5 flex flexCenter">سبد خرید خالی است.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        userInfo ? <div className="rel userBtnDiv flex flexCol">
                            <Link className="userBtn flex flexCenter" to="login">
                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="8" r="5"></circle><path d="M20 21a8 8 0 1 0-16 0"></path></svg>
                                <span className="hideText">user</span>
                            </Link>
                        </div> :
                        <Link className="loginBtn flex flexCenter" to="login">
                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M15 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path><path d="M21 12h-13l3 -3"></path><path d="M11 15l-3 -3"></path></svg>
                            <span className="faFont textSize9 textClr3">
                                ورود | ثبت نام
                            </span>
                        </Link>
                    }
                    <button className="flex flexCenter menuBtn" onClick={()=>setShowNav(true)}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"></path></svg>
                        <span className="hideText">menu</span>
                    </button>
                </div>
            </div>
        </header>
        <div className={showNav ? "navCloserDiv active" : "navCloserDiv"} onClick={()=>setShowNav(false)}>
            <button className="flex flexCenter navCloseBtn">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path></svg>
            </button>
        </div>
        <nav className={showNav ? "nav active grid" : "nav"}>
            <div className="flex alignCenter justifyBetween navLogoDiv">
                <Link className="faFont textSize12 textClr3 flex alignCenter headerMainLogo" to="/">
                    با ما <span>میرسی</span>
                </Link>
                <Link className="flex flexCenter searchIcon textClr3" to="/search">
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="8"></circle><path d="m21 21-4.3-4.3"></path></svg>
                </Link>
            </div>
            <div className="flex flexCol navLinkList">
                <Link to="" className="faFont navlinkItem flex textSize1 textClr3">
                    صفحه اصلی
                </Link>
                <div className="flex flexCol rel expandParentDiv">
                    <Link to="/courses" className="faFont navlinkItem flex textSize1 textClr3">
                        دوره ها
                    </Link>
                    <button className={toggleExpandList ? "showSubListBtn flex flexCenter textClr3 toggle" : "showSubListBtn flex flexCenter textClr3"} onClick={()=>setToggleExpandList(!toggleExpandList)}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                    </button>
                    <div className="flex flexCol expandList customScrollbar">
                        <div className="flex flexCol expandSubList">
                            <div className="flex flexCol mobileExpandParentDiv2">
                                <div className="flex flexCol rel firstSubList">
                                    <Link to="/courses/سازهای_ایرانی" className="faFont navlinkItem flex textSize9 textClr3">
                                        موسیقی
                                    </Link>
                                    <button className={subToggleMenu.one ? "subToggleBtn flex flexCenter textClr3 toggle" : "subToggleBtn flex flexCenter textClr3"} onClick={()=>setSubToggleMenu({...subToggleMenu,one:!subToggleMenu.one})}>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                                    </button>
                                    <div className="flex flexCol rel secendSubList">
                                        <div className="flex flexCol">
                                            <Link to="/courses/سازهای_ایرانی" className="faFont navlinkItem subListTitle flex textSize9 textClr3">
                                                سازهای ایرانی
                                            </Link>
                                            <div className="flex flexCol thirdSubList">
                                                <Link to="/course/تار" className="faFont navlinkItem flex textSize9 textClr3">
                                                    تار
                                                </Link>
                                                <Link to="/course/سه_تار" className="faFont navlinkItem flex textSize9 textClr3">
                                                    سه تار
                                                </Link>
                                                <Link to="/course/سنتور" className="faFont navlinkItem flex textSize9 textClr3">
                                                    سنتور
                                                </Link>
                                                <Link to="/course/تنبک" className="faFont navlinkItem flex textSize9 textClr3">
                                                    تنبک
                                                </Link>
                                                <Link to="/course/دف" className="faFont navlinkItem flex textSize9 textClr3">
                                                    دف
                                                </Link>
                                                <Link to="/course/تنبور" className="faFont navlinkItem flex textSize9 textClr3">
                                                    تنبور
                                                </Link>
                                                <Link to="/course/عود" className="faFont navlinkItem flex textSize9 textClr3">
                                                    عود
                                                </Link>
                                                <Link to="/course/دوتار" className="faFont navlinkItem flex textSize9 textClr3">
                                                    دوتار
                                                </Link>
                                                <Link to="/course/نی_انبان" className="faFont navlinkItem flex textSize9 textClr3">
                                                    نی انبان
                                                </Link>
                                            </div>
                                            <Link to="/course/سازهای_جهانی" className="faFont navlinkItem subListTitle flex textSize9 textClr3">
                                                سازهای جهانی
                                            </Link>
                                            <div className="flex flexCol thirdSubList">
                                                <Link to="/course/پیانو" className="faFont navlinkItem flex textSize9 textClr3">
                                                    پیانو
                                                </Link>
                                                <Link to="/course/ویولن" className="faFont navlinkItem flex textSize9 textClr3">
                                                    ویولن
                                                </Link>
                                                <Link to="/course/ویولن_سل " className="faFont navlinkItem flex textSize9 textClr3">
                                                    ویولن سل 
                                                </Link>
                                                <Link to="/course/کنترباس" className="faFont navlinkItem flex textSize9 textClr3">
                                                    کنترباس
                                                </Link>
                                                <Link to="/course/هنگ_درام" className="faFont navlinkItem flex textSize9 textClr3">
                                                    هنگ درام
                                                </Link>
                                                <Link to="/course/گیتار" className="faFont navlinkItem flex textSize9 textClr3">
                                                    گیتار
                                                </Link>
                                                <Link to="/course/گیتار_الکتریک" className="faFont navlinkItem flex textSize9 textClr3">
                                                    گیتار الکتریک
                                                </Link>
                                                <Link to="/course/گیتار_باس" className="faFont navlinkItem flex textSize9 textClr3">
                                                    گیتار باس
                                                </Link>
                                                <Link to="/course/هارمونیکا" className="faFont navlinkItem flex textSize9 textClr3">
                                                    هارمونیکا
                                                </Link>
                                                <Link to="/course/چنگ" className="faFont navlinkItem flex textSize9 textClr3">
                                                    چنگ
                                                </Link>
                                                <Link to="/course/قانون" className="faFont navlinkItem flex textSize9 textClr3">
                                                    قانون
                                                </Link>
                                                <Link to="/course/ترومپت" className="faFont navlinkItem flex textSize9 textClr3">
                                                    ترومپت
                                                </Link>
                                                <Link to="/course/بلز" className="faFont navlinkItem flex textSize9 textClr3">
                                                    بلز
                                                </Link>
                                                <Link to="/course/فلوت_ریکوردر" className="faFont navlinkItem flex textSize9 textClr3">
                                                    فلوت ریکوردر
                                                </Link>
                                            </div>
                                            <Link to="/course/آواز" className="faFont navlinkItem subListTitle flex textSize9 textClr3">
                                                آواز
                                            </Link>
                                            <div className="flex flexCol thirdSubList">
                                                <Link to="/course/آواز_ایرانی" className="faFont navlinkItem flex textSize9 textClr3">
                                                    آواز ایرانی
                                                </Link>
                                                <Link to="/course/آواز_پاپ" className="faFont navlinkItem flex textSize9 textClr3">
                                                    آواز پاپ
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flexCol rel firstSubList">
                                    <Link to="/courses/هنرهای_نمایشی" className="faFont navlinkItem flex textSize9 textClr3">
                                        هنرهای نمایشی 
                                    </Link>
                                    <button className={subToggleMenu.two ? "subToggleBtn flex flexCenter textClr3 toggle" : "subToggleBtn flex flexCenter textClr3"} onClick={()=>setSubToggleMenu({...subToggleMenu,two:!subToggleMenu.two})}>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                                    </button>
                                    <div className="flex flexCol rel secendSubList">
                                        <div className="flex flexCol">
                                            <Link to="/course/نویسندگی_برای_رادیو" className="faFont navlinkItem flex textSize9 textClr3">
                                                نویسندگی برای رادیو
                                            </Link>
                                            <Link to="/course/فیلمنامه_نویسی" className="faFont navlinkItem flex textSize9 textClr3">
                                                فیلمنامه نویسی 
                                            </Link>
                                            <Link to="/course/نمایشنامه_نویسی" className="faFont navlinkItem flex textSize9 textClr3">
                                                نمایشنامه نویسی
                                            </Link>
                                            <Link to="/course/کارگردانی_سینما" className="faFont navlinkItem flex textSize9 textClr3">
                                                کارگردانی سینما
                                            </Link>
                                            <Link to="/course/طراحی_و_دوخت لباس_ومد" className="faFont navlinkItem flex textSize9 textClr3">
                                                طراحی و دوخت لباس ومد
                                            </Link>
                                            <Link to="/course/گریم" className="faFont navlinkItem flex textSize9 textClr3">
                                                گریم
                                            </Link>
                                            <Link to="/course/فیلمبرداری" className="faFont navlinkItem flex textSize9 textClr3">
                                                فیلمبرداری
                                            </Link>
                                            <Link to="/course/تدوین" className="faFont navlinkItem flex textSize9 textClr3">
                                                تدوین
                                            </Link>
                                            <Link to="/course/بازیگری" className="faFont navlinkItem flex textSize9 textClr3">
                                                بازیگری
                                            </Link>
                                            <Link to="/course/بازیگری_سینما" className="faFont navlinkItem flex textSize9 textClr3">
                                                بازیگری سینما
                                            </Link>
                                            <Link to="/course/بازیگری_برای_میکروفون" className="faFont navlinkItem flex textSize9 textClr3">
                                                بازیگری برای میکروفون
                                            </Link>
                                            <Link to="/course/دوبالژ" className="faFont navlinkItem flex textSize9 textClr3">
                                                دوبالژ
                                            </Link>
                                            <Link to="/course/عروسک_سازی" className="faFont navlinkItem flex textSize9 textClr3">
                                                عروسک سازی
                                            </Link>
                                            <Link to="/course/افکتور_رادیو" className="faFont navlinkItem flex textSize9 textClr3">
                                                فولی آرتیست ( افکتور رادیو و فیلم)
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flexCol rel firstSubList">
                                    <Link to="/courses/هنرهای_تجسمی" className="faFont navlinkItem flex textSize9 textClr3">
                                        هنرهای تجسمی 
                                    </Link>
                                    <button className={subToggleMenu.three ? "subToggleBtn flex flexCenter textClr3 toggle" : "subToggleBtn flex flexCenter textClr3"} onClick={()=>setSubToggleMenu({...subToggleMenu,three:!subToggleMenu.three})}>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                                    </button>
                                    <div className="flex flexCol rel secendSubList">
                                        <div className="flex flexCol">
                                            <Link to="/course/نقاشی" className="faFont navlinkItem flex textSize9 textClr3">
                                                نقاشی
                                            </Link>
                                            <Link to="/course/سفالگری" className="faFont navlinkItem flex textSize9 textClr3">
                                                سفالگری
                                            </Link>
                                            <Link to="/course/مجسمه_سازی" className="faFont navlinkItem flex textSize9 textClr3">
                                                مجسمه سازی
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to="/teacher" className="faFont navlinkItem flex textSize1 textClr3">
                    اساتید
                </Link>
                <Link to="/blog" className="faFont navlinkItem flex textSize1 textClr3">
                    مقالات
                </Link>
                <Link to="" className="faFont navlinkItem flex textSize1 textClr3">
                    درباره ما
                </Link>
                <Link to="" className="faFont navlinkItem flex textSize1 textClr3">
                    تماس با ما
                </Link>
            </div>
            <div className="flex alignCenter navFooterDiv flexCenter">
                <a href="" className="flex flexCenter navSocialItem textClr3">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M349.33 69.33a93.62 93.62 0 0 1 93.34 93.34v186.66a93.62 93.62 0 0 1-93.34 93.34H162.67a93.62 93.62 0 0 1-93.34-93.34V162.67a93.62 93.62 0 0 1 93.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"></path><path d="M377.33 162.67a28 28 0 1 1 28-28 27.94 27.94 0 0 1-28 28zM256 181.33A74.67 74.67 0 1 1 181.33 256 74.75 74.75 0 0 1 256 181.33m0-37.33a112 112 0 1 0 112 112 112 112 0 0 0-112-112z"></path></svg>
                    <span className="hideText">
                        instagram
                    </span>
                </a>
                <a href="" className="flex flexCenter navSocialItem textClr3">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"></path></svg>
                    <span className="hideText">
                        telegram
                    </span>
                </a>
            </div>
        </nav>
    </>
}