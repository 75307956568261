import React from "react";
import "../Style/teachersList.css";
import { Link } from "react-router-dom";
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";

export default function TeachersList({data,part}){
    return <section className="flex flexCol teachersSection">
        <div className="flex flexCol teachersContainer container">
            <div className="flex alignCenter justifyBetween">
                <h2 className="faFont textSize12 textClr3 rel sectionTitle flex alignCenter gap5">
                    { part.titleLogo }
                    { part.titleText }
                </h2>
                <Link to="/teacher" className="faFont textSize1 textClr3 sectionLink flex alignCenter gap5">
                    { part.linkText }
                    { part.linkLogo }
                </Link>
            </div>
            <Swiper className="swiper teacherSwiper rel"
                modules={[Autoplay]}
                slidesPerView={1}
                autoplay={{
                    delay: 5000,
                }}
                breakpoints={{
                    649:{
                        slidesPerView: 2,
                    },
                    1100:{
                        slidesPerView: 3,
                    },
                }}
            >
                {
                    data.map((item,index)=>{
                        return <SwiperSlide key={index}>
                            <div className="grid teacherItemDiv alignCenter">
                                <Link className="flex flexCol teacherImgLink" to={"/teacher/"+item.id}>
                                    <img src={item.cover} alt={item.name} className="teacherItemImg" />
                                </Link>
                                <div className="flex flexCol teacherInfoDiv">
                                    <Link to={"/teacher/"+item.id} className="faFont textSize12 textClr3 teacherName">
                                        {
                                            item.name
                                        }
                                    </Link>
                                    <p className="faFont textSize9 textClr7 teacherExpert">
                                        {
                                            item.expert
                                        }
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    </section>
}