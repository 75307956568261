import React, { useRef, useState } from "react";
import "../Style/comment.css";
import CommentStar from "./CommentStar";
import PagePagintaion from "./PagePagination";
import { showModal } from "./Methods";

export default function Comment({data}){
    const pageNumber = useRef(1);
    const [commentData,setCommentData] = useState(data);
    const [toggleInsertComment,setToggleInsertComment] = useState(false);
    const [insertCommentData,setInsertCommentData] = useState({
        rate : 5,
        comment : "",
        loadingInsertComment : false
    });
    function changePage(newPageNumber){
        // setIsLoading({
        //     ...isLoading,data : true
        // });
        // searchParam.set("p",newPageNumber);
        // window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        // fetchData();
    }
    function submitComment(){
        if(insertCommentData.comment === ""){
            showModal("لطفا ابتدا نظر را وارد کنید.");
            return;
        }
        setInsertCommentData({...insertCommentData,loadingInsertComment:true});
        setTimeout(() => {
            setInsertCommentData({
                rate : 5,
                comment : "",
                loadingInsertComment : false
            });
            setToggleInsertComment(false);
            showModal("نظر شما با موفقیت ثبت شد.");
        }, 1500);
    }
    return <>
        <section className="grid container commentSection">
            <div className="flex flexCol commentSummary">
                <div className="flex alignCenter justifyBetween commentSummaryHeader">
                    <p className="faFont commentSummaryTitle flex alignCenter textClr7">
                        <span>میانگین نظرات : </span>
                        <span className="summaryTitleTotalRate">{data.totalRate}</span>
                        <span>از</span>
                        <span>{data.totalRateNumber}</span>
                        <span>نظر</span>
                    </p>
                    <CommentStar data={{totalRate : data.totalRate}} />
                </div>
                <button className="flex flexCenter faFont insertCommentBtn textSize11" onClick={()=>setToggleInsertComment(true)}>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M7 7h10v2H7zm0 4h7v2H7z"></path><path d="M20 2H4c-1.103 0-2 .897-2 2v18l5.333-4H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14H6.667L4 18V4h16v12z"></path></svg>
                    ثبت نظر
                </button>
            </div>
            <div className="flex flexCol commentsListSection">
                <h4 className="faFont textSize14 textClr3 commentsListTitle">
                    نظرات کاربران
                </h4>
                <div className="flex flexCol commentsList">
                    {
                        commentData.comments.map((item,index)=>{
                            return <div className="flex flexCol commentItem" key={index}>
                                <div className="flex alignCenter justifyBetween commentItemHeaderDiv">
                                    <p className="faFont textSize1 textClr3 commentUserName">
                                        {
                                            item.userName
                                        }
                                    </p>
                                    <CommentStar data={{totalRate : item.rate}} />
                                </div>
                                <p className="faFont textSize11 textClr3 commentUserBody">
                                    {
                                        item.comment
                                    }
                                </p>
                                {
                                    item.adminAnswer !=="" && <p className="faFont textSize1 textClr3 commentAdminAnswer flex flexCol">
                                        <span className="textSize1"> پاسخ پشتیبان :</span>
                                        {
                                            item.adminAnswer
                                        }
                                    </p>
                                }
                            </div>
                        })
                    }
                </div>
                <PagePagintaion data={{totalNumber:data.totalComments,
                            changePage : changePage, currentPage:pageNumber}} />
            </div>
        </section>
        <div className={`closeInsertCommentDiv ${toggleInsertComment && "active"}`} onClick={()=>setToggleInsertComment(false)}></div>
        <form className={`flex flexCol insertCommentForm ${toggleInsertComment  && "active"}`} onSubmit={(e)=>e.preventDefault()}>
            <div className="flex alignCenter justifyBetween">
                <h3 className="faFont textSize12 textClr3 insertCommentTitle">
                    ثبت نظر
                </h3>
                <button className="closeInsertCommentBtn flex flexCenter" type="button" onClick={()=>setToggleInsertComment(false)}>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path></svg>
                    <span className="hideText">close</span>
                </button>
            </div>
            <textarea name="commentBody" id="commentBody" className="faFont textSize1 textClr3 insertCommentTextArea" placeholder="نظر خود را اینجا بنویسید" required onChange={(e)=>setInsertCommentData({...insertCommentData,comment : e.target.value})} value={insertCommentData.comment}></textarea>
            <div className="flex alignCenter commentRateDiv">
                <p className="faFont rateText textSize9 textClr3">
                    امتیاز :
                </p>
                <div className="flex flexCol insertCommentRateDiv rel">
                    <div className="insertCommentRateProgress" style={{width:(insertCommentData.rate * 20)+"%"}}></div>
                    <img src="/image/star.webp" alt="star" draggable="false" className="insertCommentRateImg" width={100}/>
                    <input type="range" name="rate" id="rate" min={1} max={5} value={insertCommentData.rate} step={1} className="insertCommentRateInput" onChange={(e)=>setInsertCommentData({...insertCommentData,rate :e.target.value})}/>
                </div>
            </div>
            <button className="submitInsertComment faFont textSize1 textClrF flex flexCenter" onClick={()=>submitComment()}>ثبت نظر</button>
            {
                insertCommentData.loadingInsertComment && <div className="loadingInsertComment flex flexCenter alignCenter">
                    <p className="faFont textSize1 textClr3">در حال ثبت نظر</p>
                    <div className="loadingInsertCommentProgress"></div>
                </div>
            }
        </form>
    </>
}