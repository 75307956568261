import DOMPurify from "dompurify";
let persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"],
  englishNumbers = [/0/g, /1/g, /2/g, /3/g, /4/g, /5/g, /6/g, /7/g, /8/g, /9/g];
function numbersToPer(str) {
  if (typeof str === "string") {
    for (var i = 0; i < 10; i++) {
      str = str.replace(englishNumbers[i], persianNumbers[i]);
    }
  }
  return str;
}
function formatTime(timeInSeconds) {
  const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
  return {
    minutes: result.substr(3, 2),
    seconds: result.substr(6, 2),
  };
}
function scrollToTop() {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
}
function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
function validPhone(phoneNumber) {
  const regex = /^09[0-9]{9}$/;
  return regex.test(phoneNumber);
}
function MyComponent({ htmlContent }) {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent, {
    ALLOWED_TAGS: [
      "b",
      "i",
      "p",
      "a",
      "span",
      "br",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "img",
    ],
    ALLOWED_ATTR: ["href"],
  });
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
}
let modalTimer = null;
let modalDuration = 4000;
function showModal(msg) {
  const modalSection = document.querySelector(".modalSection");
  const modalText = document.querySelector(".modalText");
  if (modalTimer) {
    clearTimeout(modalTimer);
  }
  modalSection.classList.add("show");
  modalText.innerText = msg;
  modalTimer = setTimeout(() => {
    modalSection.classList.remove("show");
  }, modalDuration);
}

export {
  formatTime,
  numbersToPer,
  scrollToTop,
  validateEmail,
  validPhone,
  MyComponent,
  showModal,
};
